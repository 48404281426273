.selectedVehicleBar {
  position: relative;
  background-color: #ebebeb;
  .layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    max-width: 75rem;
    margin: 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }
  img {
    max-height: 100px;
  }
  .row--small {
    max-width: 67rem;
  }
  &__content {
    padding-left: 0;
    padding-right: 0;
    &--noMargin {
      margin: 0;
    }
    @include breakpoint(medium up) {
      margin: 1.5rem auto 0;
    }
  }
  &__text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
    @include breakpoint(medium up) {
      font-size: 2rem;
    }
  }
  &__span {
    color: #6a6a6a;
    white-space: nowrap;
  }
  &__btn {
    width: 100%;
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &--smaller {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
  }
}