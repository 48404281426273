.category-list {
  .category-image {
    min-height:300px;
    display:flex;
    align-items: center;
  }
  .category-name {
    text-align: center;
    h6 {
      text-transform: uppercase;
    }
  }
}