.pencil-top-search {
  padding: 11px 0;
}

.nav-search {
  display: inline-block;
  @include breakpoint (large up) {
    margin-left: 1rem;
  }
}

.search-link {
  display: flex;
  justify-content: center;
  vertical-align: center;
  margin-bottom: 0.8rem;
  &__text {
    position: relative;
    font-size: 0.9rem;
    top: 0.2rem;
    color: $black;
    font-weight: 500;
    text-decoration: underline;
    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 0.9rem;
    }
    @media only screen and (min-width: 1024px) {
      color: $white;
    }
  }
}

.search-icon {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.5rem;
  margin-right: 0.2rem;
  padding-left: 0.5rem;
  @include breakpoint (large up) {
    padding-left: 0;
    color: rgba(255, 255, 255, 0.8);
  }
}

.search-box {
  display: none;
  position: absolute;
  bottom: 0.7rem;
  width: 423px;
  z-index: 11;
  @media screen and (max-width: 1024px) {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.search-btn {
  border-radius: 0;
}