.events .event {
  &-item {
    border-bottom:1px solid $grayD;
    margin-bottom:1rem;
    padding-bottom:1rem;
    @include breakpoint (medium) {
      margin-bottom:2rem;
      padding-bottom:2rem;
    }
    @include breakpoint (large) {
      margin-bottom:3rem;
      padding-bottom:3rem;
    }
  }
  &-thumbnail {
    a {
      img {
        transition: all .3s ease-in-out;
        box-shadow: 0 3px 10px $gray9;
      }
      &:hover {
        img {
          transform: scale(1.025);
        }
      }
    }
  }
  &-information {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  &-date {
    color:$green;
    font-weight:900;
  }
  &-title {
    font-size:1.25rem;
    font-weight:700;
    color:$black;
  }
  &-location-date {
    font-weight:400;
  }
  &-link {
    a {
      color:$green;
      text-decoration: underline;
      font-weight:600;
    }
  }
}