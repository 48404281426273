// wheel information and pricing
#wheel .wheel-info, #wheel .accessory-info {
  padding:1rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 25px $grayC;
  margin-bottom:1rem;
  @include breakpoint (large) {
    padding:2.5rem;
    margin-bottom:2.5rem;
  }
  // information
  h1,h4 {
    font-size:2rem;
    text-transform: uppercase;
    letter-spacing:.2rem;
    font-weight:700;
    margin-bottom:0;
    line-height:1.1;
  }
  .pricing-style {
    display:block;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    color: $black;
    margin-bottom: 0.5rem;
    letter-spacing: 0;
    font-size:1.25rem;
  }
  .pricing-finish {
    font-family:$heading-font-family;
    font-size:1rem;
    line-height:1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1rem;
  }
  .pricing-brand {
    > a {
      color:$secondary-color;
      font-size:.9rem;
      letter-spacing:normal;
      text-transform: capitalize;
      font-weight:500;
      display:block;
      margin-bottom:.5rem;
    }
  }

  .bottom,.pricing-stock,.pricing-price,.pricing-match {
    font-family:$detail-font-family;
  }

  .pricing-specs {
    color:$black;
    margin-bottom:1rem;
    font-size:.85rem;
    @include breakpoint (large) {
      font-size:1rem;
    }
    .bottom {
      //font-size:1.25rem;
      font-weight:700;
      text-transform: uppercase;
      letter-spacing:.1rem;
      margin-bottom:.5rem;
      .front,.rear {
        > span {
          &:after {
            content:',';
          }
          &:last-child:after {
            content:'';
          }
        }
      }
    }
    .top {
      margin-bottom:.5rem;
      font-weight:500;
      > div {
        margin-bottom:.5rem;
      }
      span {
        color:$black;
        font-weight:600;
      }
      .pricing-brand {
        color:$primary-color;
      }
      .pricing-starting {
        .call-for {
          color:$blueDeep;
          text-transform: uppercase;
          font-weight:600;
        }
        .sale-item {
          color:$primary-color;
          font-weight: 700;
          text-transform: uppercase;
          margin-left:.5rem;
        }
      }

      &.non-skupage {
        flex-direction:column;
        > div {
          width:100%;
        }
      }
    }
    .pricing-stock {
      color:$green;
      text-transform: uppercase;
      font-weight:700;
      letter-spacing:.05rem;
    }
  }

  .pricing-other-specs {
    margin-bottom:1rem;
  }

  .pricing-price {
    background-color:$grayEF;
    padding:1rem;
    border-radius:.5rem;
    color:$black;
    margin-bottom:1rem;
    .title {
      font-size:1.3rem;
      font-weight:600;
      margin-bottom:.5rem;
      .sale-item {
        color:$primary-color;
        font-size:1.25rem;
      }
    }
    .subtitle {
      font-size:.9rem;
      font-weight:600;
      letter-spacing:.05rem;
      span {
        text-transform: uppercase;
      }
    }
    .price-original {
      font-weight:400;
      text-decoration: line-through;
    }
    .price-sale {
      color:$primary-color;
    }
  }
  .pricing-affirm {
    background-color:$grayEF;
    padding:1rem;
    border-radius:.5rem;
    color:$black;
    margin-bottom:1rem;
    font-size:.9rem;
    text-align:center;
    font-weight:500;
    .affirm-modal-trigger {
      display:inline-block;
      font-weight:600;
    }
    p {
      margin:0;
    }
  }

  // cta
  .pricing-match {
    margin-bottom:1rem;
    text-transform: capitalize;
    color:$black;
    font-size:.95rem;
    font-weight:600;
  }

  .pricing-vehicle {
    margin-bottom:2rem;
    h4 {
      font-size:1.25rem;  
    }
    button {
      font-family: $detail-font-family;
      font-size:.95rem;
      color:$secondary-color;
      text-decoration: underline;
      font-weight: 700;
    }
    .vehicle-title,.vehicle {
      font-family:$heading-font-family;
    }
    .vehicle-title {
      color: $green;
      text-transform: uppercase;
      font-weight: 600;
    }
    .vehicle {
      font-size: 1rem;
      font-weight: 600;
      color:$black;
    }
  }

  .cta {
    .button {
      background-color:$blueDeep;
      border-radius:.5rem;
      margin-bottom:0rem;
      width:100%;
      padding:1.6rem .5rem;
      font-size:1.05rem;
      &:hover {
        background-color:$grayC;
      }
      &.gray {
        background-color:$gray9;
        &:hover {
          background-color:$grayC;
        }
      }
      &.active {
        background-color:$black;
      }
      &#shop-by-spec-btn {
        border:2px solid $grayC;
        background-color:$white;
        color:$blueDeep;
        &:hover {
          background-color:$grayC;
          color:$white;
        }
      }
      &.open-experts-quote-popup {
        background-color:$primary-color;
        color:$black;
        &:hover {
          background-color:$secondary-color;
          color:$white;
        }
      }
    }
    &-button {
      margin-bottom:2rem;
    }
    &-link {
      text-transform: uppercase;
      margin-bottom:1.5rem;
      &:last-child {
        margin-bottom:0;
        .icon {
          background-color:$gray9;
        }
      }
      &.orange {
        .icon {
          background-color:$primary-color;
        }
      }
      
      a {
        font-family:$detail-font-family;
        color:$gray3;
        font-weight:600;
        font-size:1rem;
        letter-spacing:.05rem;
        display:flex;
        align-items:center;
        justify-content: flex-start;
      }

      .icon {
        margin-right:1.5rem;
        width:50px;
        height:50px;
        display:flex;
        align-items:center;
        justify-content: center;
        border-radius:50px;
        background-color:$primary-color;
        text-decoration: unset;
        color:$white;
        font-size:2.5rem;
      }
      .text {
        text-decoration: underline;
        font-size:.85rem;
        @include breakpoint (large) {
          font-size:1rem;
        }
      }
    }
  }
  .buy-options {
    font-family:$detail-font-family;
    padding:1rem;
    background-color:$grayD4;
    border-radius:.5rem;
    label {
      font-weight:600;
    }
    .missing {
      background-color: rgba(255,51,51,.5);
    }
  }

  .selection {
    font-family:$heading-font-family;
  }
}









#wheel .wheel-info, #wheel .accessory-info {
  img {
    max-width:200px;
  }
  .series-logo {
    @include breakpoint (small only) {
      text-align:center;
      img {
        max-width:180px;
      }
    }
  }
  .wheel-name {
    font-size:2.25rem;
    font-weight:600;
    text-transform: uppercase;
    padding-bottom:.5rem;
    @include breakpoint (small only) {
      text-align: center;
      padding-bottom:0;
      font-size:2rem;
    }
    span {
      font-weight:400;
    }
  }
}

#see-on-vehicle-detail {
  h5 {
    font-weight:600;
    letter-spacing:-1px;
    line-height:1;
    color:$black;
  }
}


// spec information
.wheel-detail-sizes {
  .title {
    font-weight:600;
  }
}


// badge information
.wheel-badges {
  display:flex;
  > div {
    margin-right:.5rem;
  }
  img {
    max-width:100px;
  }
}


// finish information
.wheel-finishes {
  
  img {
    max-width:150px;
    transition: all .3s ease-in-out;
    &:hover {
      opacity:.5;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight:600;
    color:$gray3;
    margin-bottom:1.15rem;
  }
  .detail-thumb {
    display:flex;
  }
  .wheel-finish {
    text-align: center;
    font-weight:600;
    margin-right:.5rem;
  }
}


// videos information 
.wheel-videos {
  &.finishes-border {
    border-top: 1px solid $grayA;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    color: $gray3;
    margin-bottom: 1.15rem;
  }
  .wheel-video {
    display:flex;
    > div {
      width:100%;
      max-width:50%;
    }
    a {
      display:block;
      border:1px solid $grayA;
      position:relative;
      &:hover {
        .play-icon {
          background-color:rgba(0,0,0,0);
          img {
            opacity:0;
          }
        }
      }
    }
    .play-icon {
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:flex;
      justify-content:center;
      align-items:center;
      background-color:rgba(0,0,0,.5);
      transition: all .3s ease-in-out;
      img {
        transition: all .3s ease-in-out;
      }
    }
  }
}

// mobile 
.pricing-information {
  padding:0 .625rem;
}