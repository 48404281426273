.wheel-detail-gallery {
  margin-bottom:2rem;
  @include breakpoint (large) {
    margin-bottom:3rem;
  }
  .line-title {
    .title {
      width:70%;
    }
    .line {
      width:15%;
    }
  }
}

/*
.wheel-gallery {
  &__header {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.105;
    margin: 0;
    @include breakpoint(small only) {
      margin-top: 1rem;
    }
  }
}

.wheel-galleryList {
  display: inline-block;
  margin: 0;
  @include breakpoint(small only) {
    margin-left: 0.5rem;
  }
  &__item {
    list-style: none;
    float: left;
    width: 31%;
    height: auto;
    margin-right: 2%;
    margin-top: 0.5rem;
    &:nth-child(n + 7) {
      display: none;
    }
    &--display:nth-child(n + 7) {
      display: block;
    }
  }
}

.wheel-galleryImage {
  width: 190px;
  height: 102px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include breakpoint(small only) {
    width: 95px;
    height: 60px;
  }
}

@include breakpoint(small only) {
  .wheel-galleryTxt {
    font-size: 0.8rem;
  }
}
*/
