.breadcrumbs {
  padding-top:2rem;
  //padding-bottom:2rem;
  font-weight:700;
  text-transform: uppercase;
  a {
    color:$primary-color;
    &:hover {
      color:$black;
    }
  }
}

#products {
  .breadcrumbs {
    @include breakpoint (small only) {
      padding-bottom:0;
    }
  }
}