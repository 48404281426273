.featured {
  margin-bottom:1rem;
  @include breakpoint (medium) {
    margin-bottom:3rem;
  }
  h6,p {
    color:$gray57;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight:700;
  }
  p {
    font-size:.75rem;
  }
  .featured-finish {
    p {
      font-weight:600; 
    }
  }
  
  &-list {
    @include breakpoint (small only) {
      margin-bottom:2rem;
    }
  }
  &-slider {
    position:relative;
  }
  &-prev, &-next {
    position: absolute;
    display: block;
/*    height: 20px;
    width: 20px;*/
    line-height: 0px;
    font-size: 2rem;
    cursor: pointer;
    background: transparent;
    color: $gray57;
    top: 35%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    transition:all .3s ease-in-out;
    &:hover {
      color:$gray57;
    }
  }
  &-prev {
    left:-3%;
  }
  &-next {
    right:-3%;
  }
  
  
  
  // view all
  .view-all {
    text-align:center;
    margin-bottom:2rem;
    @include breakpoint (medium) {
      display:none;
    }
    a {
      color:$gray57;
      font-weight: 500;
      font-size:1.15rem;
      &:hover {
        color:$primary-color;
      }
    }
  }
}