.news-article {
    position: relative;
    overflow: auto;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    .timestamp {
        font-size: .8rem;
        position: absolute;
        top: 0;
        left: 0;
        padding: .5rem;
        text-align: center;
        color: #fefefe;
        background: #333;
        .date {
            font-size: 2.75rem;
            font-weight: 900;
            line-height: 1;
            display: block;
        }
    }
    img {
        border-bottom: 5px solid #d40000;
    }
    .news-sidebar {
        padding: 1rem;
        background: #fbfbfb;
        li a {
            color: #505050;
            &:hover {
                color: #d40000;
            }
        }
    }
}

.blog {
  font-weight:400;
  a {
    color:$black;
    text-transform: uppercase;
    &.button {
      border-radius: 4px;
      font-weight:400;
      font-family:$body-font-family;
      font-size:1.25rem;
      width:130px;
      &.not-found {
        background-color:$grayC;
        color:$white;
        pointer-events: none;
      }
    }
  }
  h3 {
    font-family:$body-font-family;
    font-weight:600;
    font-size:1.75rem;
  }
  &-img {
    a {
      position:relative;
      display:block;
    }
  }
  &-date {
    position:absolute;
    top:0;
    left:0;
    background-color:$black;
    color:$white;
    font-weight:900;
    text-transform: uppercase;
    font-size:3.5rem;
    line-height:1;
    width:100px;
    height:100px;
    max-width:100%;
    max-height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-month {
      font-size:.9rem;
    }
  }
  &-content {

  }
  
  // Sidebar
  &-archive,&-tags {
    h4 {
      font-family:$body-font-family;
      font-size:1.5rem;
      text-transform: uppercase;
      font-weight:600;
    }
    ul {
      list-style: none;
      margin-left:0;
      i {
        color:$green;
      }
    }
    a {
      text-transform: capitalize;
    }
  }
  &-archive {
    a {
      font-weight:600;
      font-size:1.05rem;
      &.archive-active {
        color:$green;
      }
      &.year-tab {
        span {
          padding-left:1rem;
        }
      }
    }
    .menu > li:not(.menu-text) > a {
      padding:.5rem 0;
    }
    .is-accordion-submenu-parent > a::after {
      right:auto;
      left:0;
      border-color:$green transparent transparent;
      transform:rotate(-90deg);
      transition:all .2s ease-in-out;
    }
    .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
      transform:scaleY(1);
      
    }
  }
  &-tags {
    li {
      margin-right:.5rem;
      margin-bottom:.5rem;
    }
    a {
      background-color:$gray3;
      color:$grayC;
      padding:.5rem;
      font-weight:400;
      display:inline-block;
      border-radius:.2px;
      font-size:.85rem;
      &:hover {
        background-color:$black;
      }
      &.tag-active {
        background-color:$black;
        color:$green;
      }
    }
  }
}


//// Blog article

.blog {
  &-details {
  }
  .article {
    &-date {
      color:$gray3;
    }
    &-nav {
      border-top:1px solid $gray3;
    }
  }
  // gallery
  #article-gal-thumbs {
    display:grid;
    grid-template-columns:repeat(5,1fr);
    grid-template-rows:auto;
    width:100%;
    padding-top:2rem;
    padding-bottom:2rem;
    border-top:1px solid $gray3;
    border-bottom:1px solid $gray3;
    .item {
      grid-column-end: span 1;
      padding:.5rem;
    }
    a {
      display:block;
      width:100%;
      position:relative;
      &:after {
        content:'';
        position: absolute;
        background-color:$black;
        opacity:0;
        top:0;
        right:0;
        bottom:0;
        left:0;
        transition: all .3s ease-in-out;
      }
      &:hover:after {
        opacity:.5;
      }
    }
  }
}

////////////// Overrides /////////////////

.blog {
  .page-header {
    margin-bottom:1rem;
    @include breakpoint (medium) {
      margin-bottom:3rem;
    }
    .row {
      border-bottom:1px solid $grayC;
      padding-bottom:2rem;
    }
    .blog-filter {
      display:flex;
      justify-content: flex-end;
      align-items:center;
      h4 {
        margin-bottom:0;
        text-transform:uppercase;
        font-weight:700;
        font-size:1.5rem;
      }
      form {
        font-weight:100;
        height:100%;
        margin-top:-10px; // No idea where extra space is coming from
      }
      select {
        margin-bottom:0;
        @include breakpoint (large) {
          width:375px;
        }
      }
      &-title, &-list {
        line-height:1;
      }
      &-title {
        margin-right:1rem;
      }
      
    }
  }
}


///// Landing //////
.blog {
  h3 {
    text-transform: uppercase;
    color:$black;
    line-height:1.25;
  }
  hr {
    border-color:$grayC;
    margin:2rem auto;
  }
  a {
    color:$green;
    text-transform: uppercase;
    font-weight:700;
    &:hover {
      color:$gray6;
    }
  }
  img {
    width:100%;
  }
  &-date {
    z-index:2;
  }
  &-description {
    margin-bottom:2rem;
  }
}


///// Details /////
.blog.blog-details {
  h3 {
    line-height:1;
    margin-bottom: .5rem;
  }
  .page-header {
    margin-bottom:1rem;
  }
  .article-header {
    margin-bottom:1.5rem;
  }
  
  // thumbs
  #article-gal-thumbs {
    border:0;
    padding:0;
    .item {
      padding:.15rem;
    }
  }
  // social media
  .article-share {
    text-align:center;
  }
  // navigation 
  .article-nav {
    border-color:$grayC;
    a.button {
      padding-left:0;
      padding-right:0;
      color:$white;
      &:focus {
        background:$green;
      }
      &.not-found {
        background-color:$grayD;
      }
    }
  }
}