.gallery-quote-popup {
  &__header {
    font-size: 1.7rem;
    text-align:center;
    color:$primary-color;
    text-transform: uppercase;
  }
  &__txt {
    font-size: 0.95rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    @include breakpoint(small only) {
      line-height: 1.2;
    }
  }
  &__note {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  label {
    font-family:$detail-font-family;
    font-size: 1rem;
    font-weight: 700;
  }
  input:not([type="submit"]) {
    font-family:$detail-font-family;
    background-color: #eee;
    box-shadow: none;
    border: none;
    color: #000;
  }
  
}

.form-name {
  display: flex;
  justify-content: space-between;
  @include breakpoint(small only) {
    flex-direction: column;
  }
  input {
    width: 19.5rem;
    @include breakpoint(small only) {
      width: 100%;
    }
    &::placeholder {
      color: #333;
      font-size: 0.9rem;
    }
  }
}

.gallery-product-info {
  p {
    color:$black;
  }
  span {
    font-weight: 600;
  }
}
