#products .search-by-vehicle {
  margin-bottom:2rem;
  text-align:center;
  padding-left:.625rem;
  padding-right:.625rem;
  select {
    @include breakpoint (large) {
      margin-bottom:0;
    }
  }
  .row {
    padding:1rem;
    border:1px solid $gray3;
    > .column {
      @include breakpoint (large) {
        padding-right:.5rem;
        &:first-child, &:last-child {
          padding-right:0;
        }
      }
    }
  }
  .search-title {
    font-size:1.25rem;
    color:$black;
    font-weight:600;
    text-transform: uppercase;
    margin-bottom:.5rem;
    line-height:1;
  }
  a {
    text-decoration: underline;
  }
}