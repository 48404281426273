.hp .shipping {
  background-color:$primary-color;
  padding:1rem .5rem;
  display:flex;
  align-items: center;
  justify-content: center;
  h5 {
    color:$black;
    text-transform: uppercase;
    font-size:1rem;
    font-weight:800;
    margin:0 0.5rem;
    text-align:center;
    line-height:1;
    @include breakpoint (medium) {
      font-size:1.75rem;
      margin:0 1.25rem;
    }
  }
  i {
    font-size:1.5rem;
    color:$black;
    @include breakpoint (medium) {
      font-size:2rem;
    }
  }
}