.newsletter {
  background-color:$gray1D;
  padding-top:3rem;
  padding-bottom:3rem;
  input {
    border-radius: 2px!important;
    margin:0;
    border:0;
  }
  .title,.content {
    text-align:center;
  }
  .content {
    max-width:530px;
    margin:0 auto;
  }
  h2 {
    color:$white;
    @include breakpoint (medium down) {
      font-size:1.5rem;
    }
  }

  .input-group-field {
    width:100%;
    padding:.75rem;
    height:auto;
    font-size:.9rem;
    font-weight:500;
    margin-right:10px;
    @include breakpoint (medium) {
      margin-right:10px;
      width:410px;
      margin-bottom:0;
    }
  }
  .input-group {
    display:flex;
    justify-content:center;
    @include breakpoint (medium) {
      align-items:normal;
    }
    &-button {
      @include breakpoint (small only) {
        width:auto;
      }
    }
  }
  .button {
    background-color:$primary-color;
    letter-spacing:.1rem;
    padding:1.05rem 1.5rem;
    &:hover {
      background-color:$gray6;
    }
  }
}

/*.newsletter {
  background-color: $primary-color;
  padding: 1rem 0.5rem;
  @include breakpoint(medium up) {
    padding: 2rem;
  }
  &--position {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }
  &__header {
    color: $white;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 0.7rem;
    @include breakpoint(medium up) {
      font-size: 2rem;
      margin: 0;
    }
  }
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  &__input {
    display: inline-block;
    margin: 0;
    &--font {
      font-family: FontAwesome, 'roboto';
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &__btn {
    background-color: $black;
    margin: 0;
  }
}
*/