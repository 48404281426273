.hp .fitment {
  &-bar {
    padding:2rem 0;
    box-shadow:0 5px 10px $grayC;
  }
  h3 {
    text-transform: uppercase;
    font-weight:900;
    display:inline;
    color:$blueDeep;
    border-bottom:4px solid $primary-color;
  }
  .search-by {
    display:flex;
    align-items: center;
    flex-direction: column;
    @include breakpoint (large) {
      flex-direction: row;
    }
    > div {
      &:first-child {
        margin-bottom:1.5rem;
        @include breakpoint (large) {
          margin-bottom:0;
          width:25%;
        }
      }
      &:last-child {
        width:100%;
        @include breakpoint (large) {
          width:75%;
        }
      }
    }
  }

  // elite
  #wheels-vehicle-select-elite, #tires-vehicle-select-v {
    select {
      margin-bottom:0;
    }
    #icv_vehicle-select {
      flex-wrap:nowrap;
      flex-direction:column;
      @include breakpoint (medium) {
        flex-direction:row;
      }
    }
    .icv_vehicle-select-option {
      width: 100%;
      border-radius:3px;
      margin-bottom:1rem;
      @include breakpoint (medium) {
        margin-left:1.25rem;
        &:first-child {
          margin: 0;
        }
      }
      &:last-child{
        margin-right:0;
      }
    }
    .vs--unsearchable {
      background-color:$white;
      width:100%;
      margin-bottom:5px;
      @include breakpoint (medium) {
        margin-bottom:0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      input {
        background-color:$white;
        margin:0;
      }
      .vs__dropdown-toggle {
          border:0;
          padding:0;
          width:100%;
        .vs__search {
          font-weight:600;
        }
      }
      &.vs--disabled {
        background-color:$grayE6;
        input {
          background-color:$grayE6;
        }
        .vs__dropdown-toggle {
          background-color:$grayE6;
          border:0;
        }
        svg {
          background-color:$grayE6;
        }
      }
    }
    .vs__selected {
      font-weight:600;
      &-options {
        flex-wrap: nowrap;
      }
    }
    .vs__dropdown-menu {
      overflow-x: visible;
      width:auto;
    }
  }
}
