.hp .instagram {
  background-color:$blueDeep;
  padding-bottom:1rem;
  h2 {
    text-align: center;
    margin-bottom:0;
    padding:.75rem 0;
  }
  a {
    font-size:1.25rem;
    color:$white;
    font-weight:600;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color:$primary-color;
    }
    i {
      font-size:1.75rem;
      margin-right:.5rem;
    }
  }
}