#package {
  padding-top:2rem;
  @include breakpoint (large) {
    padding-top:3.5rem;
    margin-bottom:4rem;
  }
  h4 {
    font-family:$body-font-family;
  }
  .wheel-detail-stage,.tire-detail-stage {
    //margin-bottom:2rem;
  }


  .wheel-sizes {
    margin-top: 1rem;
    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .wheel-details {
    background: #f1f1f1;
    padding: 1rem 1.5rem;
    .wheel-paragraph {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  .loadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }
}

.gallery-popup {
  display: table;
  margin: 0 auto;
  .galleryView-info {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    span {
      display: block;
    }
  }
  .popup-car {
    font-weight: 900;
    font-size: 1.3rem;
    color: #fff;
  }
  .mfp-close {
    color: white !important;
    position: relative;
    float: right;
  }
}

@include breakpoint(medium up) {
  .accordion-content {
    padding: 0;
    border: 0;
    border-bottom: 0 !important;
  }
  .alt-images {
    .slick-slide {
      //width: 100% !important;
    }
  }
}

.detail-thumb {
  p {
    line-height: 1;
    margin: 0.25rem;
  }
  .finish-name {
    color: $dark-gray;
    font-size: 0.8rem;
  }
  img {
    transition: transform 0.3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}

#specs {
  margin-bottom: 1.25rem;
  overflow: auto;
}

.see-on-vehicle-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.2rem;
  margin: 0.5rem 0;
}

.see-on-vehicle-header {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.see-on-vehicle-select {
  margin-bottom: 0;
}

table {
  border: 0;
  margin-bottom: 0;
  tr {
    td,
    th {
      text-align: center;
    }
    th {
      background: $light-gray;
    }
  }
}

@include breakpoint(small only) {
  #package {
    .banner-top {
      .wheel-banner {
        height: 6rem;
      }
      .heading {
        font-size: 1.7rem;
        top: 90%;
      }
    }
    .slick-slide {
      display: inline-block;
      float: none;
    }
  }
}

@include breakpoint(medium up) {
  .finish-header {
    display: none;
  }
}



#package {
  .wheel-content {
    /*background-color:$white;
    float:left;
    box-shadow: 0px 0px 5px $grayB;
    padding-bottom:2rem;
    
    &-padding {
      float:left;
      border:1px solid $grayC;
      background-color:$grayE;
      padding:1.85rem;
      width:100%;
    }
    &.row {
      max-width:75rem;
      width:100%;
    }*/
    &.row {
      //width:100%;
    }
  }

   ///// left /////
   .left {
    .products, .about {
      margin-bottom:2rem;
    }
    .products {
      float:left;
      padding-top:2rem;
      width:100%;
    }
    .product-title {
      font-family:$body-font-family;
      font-weight:700;
      font-size:1.25rem;
      text-align:center;
      color:$black;
      span {
        color:$secondary-color;
      }
    }

    .gallery-image {
      padding:1.75rem;
      padding-right:3rem;
    }
    // .spec-information-container, .gallery-description-container, .gallery-related-container,.about-container {
    //   padding-left:.9rem;
    //   padding-right:2.1rem;
    // }
    
    .pkg-img {
      position: relative;
      &-wheel {
        width: 80%;
        margin-left: auto;
        display: block;
      }
      &-tire {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 55%;
      }
    }
    
    .pkg-info {
      margin-bottom: 1rem;
      &-header {
        font-size: 1.2rem;
      }
      &-wheel,
      &-tire {
        font-weight: 400;
        color: $black;
      }
      &-price {
        font-weight: 700;
        color: $black;
      }
      .red {
        color: $primary-color;
      }
    }
    
    .pkg-btn {
      width: 75%;
    }
    // stage (gallery)
    #specials-wheel-info,#specials-tire-info {
      border-bottom:0;
      .spec-information-title {
        @include breakpoint (small only) {
          display:none;
        }
      }
    }
    .tire-detail-stage,.wheel-detail-stage {
      a {
        display:block;
      }
    }
    .tire-detail-gallery,.wheel-detail-gallery {}
    .accordion {
      padding-left:.625rem;
      padding-right:.625rem;
      @include breakpoint(medium) {
        padding-right:0;
        padding-left:0;
      }
    }
    .accordion-title {
      //background-color: $gray3;
      color: $white;
      padding: 0.6rem 0.75rem;
      font-family:$body-font-family;
      text-transform: uppercase;
      line-height: 1;
      font-size: 1.35rem;
      font-weight: 700;
    }
    // spec information
    .spec-information-container {
      .spec-information {
        border-bottom:1px solid $gray9;
        padding-bottom:1rem;
        margin-bottom:2rem;
        font-family:$detail-font-family;
        letter-spacing:.05rem;
        &-title { 
          color:$gray3;
          font-size: 1.5rem;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: .1rem;
          border-bottom:1px solid $gray9;
          margin-bottom:1rem;
        }
        &-item {
          display:flex;
          font-size:.9rem;
          font-weight:500;
          color:$gray3;
          text-transform: uppercase;
          &:nth-child(odd) {
            > div {
              background-color:$grayE;
            }
          }
          > div {
            width:50%;
            padding:.5rem;
            margin:0 2px;
            &:first-child {
              margin-left:0;
            }
            &:last-child {
              margin-right:0;
            }
          }
        }
      }
    }
    
    // stage gallery (package specials);
    .stage-arrow {
      position: absolute;
      height:100%;
      z-index:3;
      color:$primary-color;
      top:0;
      &.stage-prev {
        left:-10px;
      }
      &.stage-next {
        right:-10px;
      }
    }
    
    // description
    .gallery-description {
      padding:1rem;
      &-divider {
        border-top:1px solid $grayC;
      }
    }
    
    // related content
    .gallery-related {
      padding:1.5rem 0;
      &-title {
        background-color:$gray3;
        color:$white;
        padding:.6rem .75rem;
        font-family:$body-font-family;
        text-transform: uppercase;
        line-height:1;
        font-size:1.35rem;
        font-weight: 700;
      }
    }

    // about / description 
    .about-container {
      h4,p,.wheel-sizes {
        color:$gray3;
      }
      h4 {
        font-size:1.5rem;
        color:$gray3;
        letter-spacing:.1rem;
        font-weight:700;
        text-transform: uppercase;
      }
      p {
        font-size:.9rem;
        font-weight:500;
        line-height:2;
      }
      .wheel-sizes {
        font-size:1rem;
        font-weight:600;
        margin-bottom:1rem;
      }
    }    
  }

  ///// right /////
 .right {
  //text-transform: uppercase;
  .lower {
    text-transform: lowercase;
  }
  // pricing
  .pricing {
    font-family:$body-font-family;
    font-weight:700;
    //text-align: center;
    color:$gray3;
    //line-height:1;
  }
  .image {
    margin-bottom:1.15rem;
  }
  .symbol {
    margin-top:5px;
    font-size:1rem;
  }
  
  .price {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:$gray3;
    color:$white;
    text-transform: uppercase;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-bottom:.5rem;
    .symbol {
      font-size:1.5rem;
      margin-top:-10px;
    }
    .value {
      font-size:2.5rem;
    }
    .precursor {
      font-size:2rem;
      text-transform: capitalize;
      padding-right:.5rem;
      @include breakpoint (small only) {
        display:none;
      }
    }
  }
  .promotions {
    padding-left:1rem;
    padding-right:1rem;
    @include breakpoint (medium) {
      padding-left:.5rem;
      padding-right:.5rem;
    }
    .promotion {
      display:flex;
      align-items: center;
      margin-bottom:.5rem;
      text-align:left;
      .icon {
        margin-right:1rem;
      }
    }
  }
  
  .financing {
    padding-left:.625rem;
    padding-right:.625rem;
    .row > {
      .column {
        margin-bottom:0.9375rem;
        &:nth-child(odd) {
          padding-right:.46875rem;
        }
        &:nth-child(even) {
          padding-left:.46875rem;
        }
      }
    }
  }

  .stock {
    border-top:1px solid $grayC;
    border-bottom:1px solid $grayC;
    padding-top:1.25rem;
    padding-bottom:1.25rem;
    padding-left:.5rem;
    font-weight:700;
    font-style: italic;
    font-size:1.5rem;
    text-align: left;
    margin-right:1rem;
    margin-left:1rem;
    margin-bottom:1rem;
    @include breakpoint (medium) {
      margin-right:1.75rem;
      margin-left:0;
    }
    &-tires, &-wheels {
      //margin-bottom:1rem;
      .title {
        margin-bottom:.5rem;
      }
      .quantity {
        font-style:normal;
        font-size:1rem;
        margin-bottom:.25rem;
        font-weight:500;
      }
    }
  }  

  .vehicle-information {
    border-bottom:1px solid $grayC;
    padding-bottom:1.25rem;
    padding-left:.5rem;
    margin-bottom:1rem;
    margin-right:1.75rem;
    font-size:normal;
    font-size:1rem;
    margin-bottom:1rem;
    font-weight:500;
    text-align:left;
    > div {
      margin-bottom:.25rem;
    }
    .title {
      margin-bottom:.5rem;
      font-style:italic;
      font-size:1.5rem;
      font-weight:700;
    }
    .information {
      margin-bottom:.5rem;
    }
    .button {
      color:$primary-color;
      text-transform: capitalize;
      padding:0;
      border:0;
      background-color: transparent;
      margin-bottom:0;
    }
  }
  
  // cta
  .cta {
    padding:0;
    //padding-right:1.5rem;
    //margin-bottom:5rem;
    //padding-left:1rem;
    //`padding-right:1rem;

    ul {
      list-style: none;
      margin:0;
    }
    li {
      margin-bottom:1rem;
    }
    a {
      /*
      padding:1.25rem 0;
      color:$black;
      background-color:$grayE;
      box-shadow: 5px 5px 0 $gray6;
      border-radius:0;
      font-size:1rem;
      max-height:60px;
      display:block;
      letter-spacing:.1rem;
      font-weight:500;
      font-family:$body-font-family;*/
      &.red {
        background-color:$red;
        color:$white;
        font-weight:600;
      }
      &.gold {
        background-color:$primary-color;
        color:$white;
        font-weight:600;
      }
      &.grayC {
        background-color:$grayC;
        font-weight:700;
      }
      &.save {
        font-size:1.5rem;
      }
      &.buy-toggle {
        margin-bottom:.25rem;
        &.active {
          background-color:$gray3;
          color:$white;
        }
      }
      &.financing-toggle {
        margin-bottom:.25rem;
        &.active {
          background-color:$gray3;
          color:$white;
        }
      }
    }
    
    // year make model - ymm
    /*.ymm-info {
      background-color:$grayE;
      padding:1rem;
      label {
        font-size:1rem;
        font-weight:700;
        line-height:1.25;
        margin-bottom:.5rem;
      }
      button {
        border-radius: 0;
        color:$white;
        width:100%;
        margin-bottom:0;
        font-size:1rem;
        padding-top:1.25rem;
        padding-bottom:1.25rem;
      }
      .required {
        font-weight:400;
        span {
          color:$red;
        }
      }
      .missing {
        background-color: rgba(255,51,51,.5);
      }
    }*/
    // fincancing options
    .financing-options {
      text-align:center;
      > a {
        box-shadow: none;
        max-height: none;
        span {
          cursor: pointer;
        }
      }
      .financing-options-paytomorrow {
        img {
          margin-right:.5rem;
          max-width:125px;
        }
      }
    }
  }
  
  // share
  .share {
    text-align:center;
  }

  // mobile ////////////////
  // spec information
  .spec-information-container {
    .spec-information {
      border-bottom:1px solid $gray9;
      padding-bottom:1rem;
      margin-bottom:2rem;
      font-family:$detail-font-family;
      letter-spacing:.05rem;
      &-title { 
        color:$gray3;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .1rem;
        border-bottom:1px solid $gray9;
        margin-bottom:1rem;
      }
      &-item {
        display:flex;
        font-size:.9rem;
        font-weight:500;
        color:$gray3;
        text-transform: uppercase;
        &:nth-child(odd) {
          > div {
            background-color:$grayE;
          }
        }
        > div {
          width:50%;
          padding:.5rem;
          margin:0 2px;
          &:first-child {
            margin-left:0;
          }
          &:last-child {
            margin-right:0;
          }
        }
      }
    }
  }
  // about / description 
  .about-container {
    h4,p,.wheel-sizes {
      color:$gray3;
    }
    h4 {
      font-size:1.5rem;
      color:$gray3;
      letter-spacing:.1rem;
      font-weight:700;
      text-transform: uppercase;
    }
    p {
      font-size:.9rem;
      font-weight:500;
      line-height:2;
    }
    .wheel-sizes {
      font-size:1rem;
      font-weight:600;
      margin-bottom:1rem;
    }
  }  
}

  // bottom 
  .quote {
    float:left;
    padding-top:2rem;
    padding-bottom:0;
    width:100%;
    .column,.columns {
      padding:0 1rem;
    }
  }
}