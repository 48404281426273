#accessory { 
  .content-header {
    position: relative;
    padding:0;
    border-top:2px solid $gray6;
    h1 {
      color:$white;
      padding:.5rem 0;
      background:rgba(0,0,0,0.7);
      @include breakpoint (large) {
        padding:1.8rem 0 2.25rem;
      }
    }
    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $gray3;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index:-1;
    }
  }

  // text overflow 
  .text-overflow-button {
    display:none;
    padding-top:.5rem;
  }
}

.accessory-image {
  max-width: 500px;
}
.accessory-name {
  font-weight: 700;
  font-size: 1.5rem;
}
.accessory-details {
  background: #f1f1f1;
  padding: 1rem 1.5rem;
}

.accessory-detail {
  .breadcrumbs {
    @include breakpoint (small only) {
      padding-bottom:0;
    }
  }
  #detail-top {
    padding-bottom:1.5rem;
    .disclaimer {
      font-size:.8rem;
      font-weight:400;
      text-align:center;
      margin-bottom:.5rem;
    }
  }
  // stage
  .main-image {
    border:1px solid $gray9;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:2rem;
    }
    img {
      width:100%;
    }
  }
  .alt-images {
    margin-bottom:1rem;
    @include breakpoint (small only) {
      margin-bottom:0rem;
    }
    img {
      transition:all .2s ease-in-out;
      &:hover {
      transform: scale(1);
    }
  }
    .slick-track {
      display:flex;
      justify-content:center;
      margin-top:0;
      > .slick-slide:last-child {
        margin-right:0;
      }
    }
    .slick-slide {
      border:1px solid $gray9;
      margin-right:.5rem;
      background-color:$primary-color;
      max-width:136px;
      &:hover {
        img {
          opacity:.65;
        }
      }
    }
  }
  ////// lighting stage
  .lighting-main-image {
    border:1px solid $gray9;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:2rem;
    }
    img {
      width:100%;
      margin:0 auto;
    }
  }
  .lighting-alt-images {
    margin-bottom:1rem;
    @include breakpoint (small only) {
      margin-bottom:0rem;
    }
    img {
      transition:all .2s ease-in-out;
      &:hover {
      transform: scale(1);
    }
  }
    .slick-track {
      width:100%!important;
      //display:flex;
      //justify-content:center;
      //margin-top:0;
      //> .slick-slide:last-child {
      //  margin-right:0;
      //}
      > .slick-slide {
        &:nth-child(4n) {
          margin-right:0;
        }
      }
    }
    .slick-slide {
      border:1px solid $gray9;
      margin-right:.5rem;
      background-color:$primary-color;
      max-width:136px;
      margin-bottom:.5rem;
      &:hover {
        img {
          opacity:.65;
        }
      }
    }
  }
  // details bottom
  .details-spec-description-container {
    padding-bottom:0;
  }
  .text-overflow-button {
    //padding-bottom:2rem;
  }
}
#accessory {
  .slick-dots li button {
    display:none;
  }
}

.detail-bottom {
  padding:1rem .5rem;
  border:1px solid $gray9;
  float:left;
  clear:both;
  margin-bottom:1rem;
  @include breakpoint (medium) {
    padding:2rem;
    margin-bottom:5rem;
  }
  &.row {
    margin-left:0;
    margin-right:0;
    > .column > div {
      margin-bottom:1rem;
      @include breakpoint (large) {
        margin-bottom:2rem;
      }
      &.kit-components {
        iframe {
          max-width:560px;
          width:100%;
          height:200px;
          @include breakpoint (large) {
            min-height:315px;
          }
        }
      }
    }
  }
  .downloads {
    ul {
      list-style: none;
      margin-left:0;
      li {
        margin-bottom:1rem;
      }
    }
    a {
      display:flex;
      align-items: center;
      &:hover {
        .downloads-icon {
          background-color:$black;
          transition: all .3s ease-in-out;
          i {
            color:$gray3;
            transition: all .3s ease-in-out;
          }
        }
        .downloads-title {
          color:$gray3;
          transition: all .3s ease-in-out;
        }
      }
    }
    &-icon {
      width:50px;
      height:50px;
      background-color:$primary-color;
      display:flex;
      justify-content: center;
      border-radius: 50px;
      margin-right:1rem;
      i {
        display:flex;
        align-items: center;
        color:$black;
        font-size:2rem;
      }
    }
    &-title {
      font-family: $heading-font-family;
      color:$black;
      text-transform: uppercase;
      font-size:1.5rem;
    }
  }
}

.qualifier {
  border-top:1px solid $black;
  margin-top:3rem;
  padding-top:1rem;
  line-height:1;
  h4 {
    text-transform: uppercase;
    line-height:1;
  }
}