.page {
    padding-bottom: 50px;
    &__content {
        padding-top: 50px;
        h1, h2 {
            text-transform: initial;
            font-family:$heading-font-family;
        }
    }
}
.page-header {
    font-size: 2.5rem;
    font-weight: 700;
    font-family:$body-font-family;
}

#page {
  // header
  h1 {
    text-transform:uppercase;
    color:$black;
    @include breakpoint (large) {
      font-size:2.25rem;
      font-weight:700;
      letter-spacing:.2rem;
    }
  }
  .content-header {
    margin-bottom:2rem;
    background-color:$gray6;
      background-size:cover;
      background-position: center;
      text-align:center;
      
      display:flex;
      align-items: center;
      justify-content: center;
      margin-bottom:4rem;
      padding:0;
    @include breakpoint (large) {
      height:240px;
      margin-bottom:6rem;
    }
    h1 {
      color:$white;
    }
    .content-header-overlay {
      background-color:rgba(0,0,0,.25);
      padding-top:2rem;
      padding-bottom:2rem;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: center;
      height:100%;
    }
  }

  // content
  .page-content {
    h2 {
      font-size:1.5rem;
      font-weight:600;
    }
    p {
      font-weight:500;
      margin-bottom:2rem;
    }
    .title {
      margin-bottom:2rem;
      @include breakpoint (large) {
        margin-bottom:4rem;
      }
      h2 {
        font-size:1.85rem;
        font-weight:700;
      }
    }
  }
}
