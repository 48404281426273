.footer {
  a, p, h5 {
    color:$black; 
  }
  a,p {
    font-size:1rem;
    font-weight:400;
    margin-bottom:.25rem;
    line-height:1.5;
  }
  h5 {
    font-weight:700;
    margin-bottom:1rem;
    color:$blueDeep;
    text-transform: uppercase;
    font-size:1.25rem;
  }
  ul {
    list-style: none;
    margin:0;
  }
  li {
    line-height:1;
  }
  a {
    display:inline-block;
    &:hover {
      color:$primary-color;
    }
  }
  .row {
    border-top:1px solid $gray6A;
    &:first-child {
      padding-top:3rem;
      padding-bottom:3rem;
      margin-bottom:2rem;
    }
    &:last-child {
      background-color:$black;
      padding:1rem 0;
    }
  }
  // logo //////////////
  &-logo {
    text-align:center;
    @include breakpoint (medium) {
      text-align: left;
      //margin-bottom:4rem;
    }
    img {
      /*max-width:25%;
      @include breakpoint (medium) {
        max-width:50%;
      }*/
    }
  }
  .footer-top {
    display:flex;
    align-items: flex-end;
    margin-bottom:1rem;
    .footer-logo {
      margin-right:2rem;
    }
    .footer-contact {
      text-transform: uppercase;
      font-weight:700;
      color:$black;
      .subtitle {
        font-family:$heading-font-family;
      }
      .title {
        font-size:1.5rem;
        font-weight:900;
      }
    }
  }
  .footer-contact-hours,.footer-social-content {
    padding-left:.75rem;
    padding-right:.75rem;
  }
  .footer-contact-hours {
    margin-bottom:2rem;
  }
  .footer-social-content {
    ul {
      display:flex;
      li {
        margin:0 .75rem;
        @include breakpoint (medium) {
          margin-right:1rem;
          margin-left:0;
        }
      }
      a {
        font-size:1.75rem;
      }
    }
  }
  &-copy {
    text-align:center;
    p {
      font-size:.9rem;
      color:$gray9;
      font-family: $heading-font-family;
      text-transform: uppercase;
      font-weight:600;
      a {
        font-size:.9rem;
        font-weight:600;
        color:$gray9;
        &:hover {
          color:$primary-color;
        }
      }
      span {
        margin-left:.25rem;
        margin-right:.25rem;
      }
    }
  }
  .footer-menu-list {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:0;
    }
  }

  // menu /////////////////
  &-menu {
    /*
    @include breakpoint (medium only) {
      margin-bottom:3rem;
    }*/
    &-collection {
      display:table;
      width:100%;
      padding-bottom:2rem;
      // mobile
      .accordion {
        background-color:transparent;
        margin-bottom:1.25rem;
        h5 {
          @include breakpoint (small only) {
            margin-bottom:0;
          }
        }
        .fas {
          padding-right:1rem;
        }
        &-title {
          font-size:1rem;
          color:$black;
          border:0;
          text-transform: uppercase;
          display:flex;
          align-items: center;
          justify-content: space-between;
          padding:0;
          padding-top:1rem;
          @include breakpoint (small only) {
            margin-bottom:.25rem;
          }
          &:focus {
            background-color:transparent;
          }
          &:hover {
            background-color:transparent;
            color:$primary-color;
          }
          &::before {
            content:'';
          }
        }
        &-content {
          border:0;
          padding:0;
          background-color:transparent;
          color:$gray6;
          @include breakpoint (small only) {
            margin-top:2rem;
          }
        }
        &-item {
          @include breakpoint (small only) {
            border-bottom:1px solid $gray6;
          }
          &:first-child> :first-child {
            border-bottom:0px;
          }
        }
      }
      .footer-menu-list {
        @include breakpoint (small only) {
          padding-top:1rem;
        }
      }
    }
    &.small-15 {
      width:50%;
      padding-left:0;
      padding-right:0;
      @include breakpoint (medium) {
        width:25%;
      }
      @include breakpoint (large) {
        width:12.5%;
      }
    }
  }
}