.dealers {
  #dealers-middle h1, h2, h3 {
    text-transform: uppercase;
    font-size:1.85rem;
    font-weight:600;
    span {
      font-weight:400;
    }
  }
  h2 {
    font-size:1.55rem;
  }
  a {
    font-weight:600;
  }
  .distance {
    color:$green;
    font-weight:900;
  }
  .input-group-field {
    height:3rem;  
  }
  .button:focus {
    background-color:$green;
  }
}