.featured-brands {
  padding-top:4rem;
  @include breakpoint (large) {
    margin-bottom:2.5rem;
  }
  h2 {
    text-align:center;
    color:$black;
    @include breakpoint (medium down) {
      font-size:1.5;
      line-height:1;
    }
  }
  .title {
    margin-bottom:2rem;
  }


  a {
    background-color:$grayF3;
    color:$black;
    font-size:.75rem;
    letter-spacing:.05rem;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
    text-transform: uppercase;
    border-radius:5px;
    @include breakpoint (large) {
      min-height:218px;
    }
  }
  .featured-brand-item {
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:0;
    }
  }
  .featured-brand-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 148px;
    padding-top:1rem;
    padding-bottom:1rem;
    @include breakpoint (large) {
      padding-top:0;
      padding-bottom:0;
    }
    .image {
      height:100%;
      display:flex;
      align-items:center;
    }
  }
}

/*.featured-brands {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include breakpoint(medium up) {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  &__header {
    font-size: 1.5rem;
    @include breakpoint(medium up) {
      font-size: 2.7rem;
    }
  }
  &__text {
    padding: 0 1rem;
    margin-bottom: 0;
    @include breakpoint(medium up) {
      padding: 0 5rem;
      margin-bottom: 4rem;
    }
  }
  &__bottom {
    margin-bottom: 4rem;
  }
}

.brand-links {
  margin-bottom: 3rem;
  &__link {
    margin-bottom: 1rem;
  }
}
*/