.fitmentCheck {
  &__txt {
    color: $primary-color;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  &__btn {
    background-color: $primary-color;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

#fitment-vehicle-select-popup {
  .vs--unsearchable {
    width:100%;
    background-color:transparent;
    margin-bottom:1rem;
    border:1px solid $grayE6;
    input {
      background-color:transparent;
      margin:0;
    }
    .vs__dropdown-toggle {
      border:0;
      padding:0;
      .vs__search {
        //font-weight:600;
      }
    }
    &.vs--disabled {
      background-color:$grayE6;
      input {
        background-color:$grayE6;
      }
      .vs__dropdown-toggle {
        background-color:$grayE6;
        border:0;
      }
      svg {
        background-color:$grayE6;
      }
    }
    .vs__selected {
      font-weight:600;
    }
  }
}