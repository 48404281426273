.home-slider-container {
  margin-bottom: 0;
  .slick-slide {
    position: relative;
  }
  img {
    width:100%;
  }
}

.home-slider-img {
  width: 100%;
}

.hero {
  position: relative;
  &-link {
    display: block;
  }
}


// slider information
.slider-content {
  text-align: right;
  margin-top:-180px;
  margin-bottom:.5rem;
  @include breakpoint (small only) {
    padding-left:1rem;
    padding-right:1rem;
  }
  @media screen and (max-width:480px) {
    margin-top:-125px;
  }
  .slider-information {
    display:inline-block;
  }
  .slider-title {
    text-align: center;
    @include breakpoint (medium) {
      text-align:right;
    }
    h1 {
      line-height:.75;
      text-transform: uppercase;
      margin-bottom:.5rem;
      color:$white;
      @include breakpoint (small only) {
        font-size:2.5rem;
      }
      @media screen and (max-width:480px) {
        font-size:1.5rem;
        line-height:.9;
      }
      span {
        font-weight:500;
      }
    }
  }
  .to-skew {
    box-shadow: 0 0 10px $black;
    background-color:$black;
    @include breakpoint (medium) {
      transform:skew(-15deg);  
      background-image:linear-gradient(90deg, $black 50%, $white 50%);
    }
  }
  .un-skew {
    @include breakpoint (medium) {
      transform:skew(15deg);
    }
  }
  .slider-wheel {
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction:column-reverse;
    @include breakpoint (medium) {
      flex-direction: row;
    }
    img {
      max-width:80px;
      @include breakpoint (medium) {
        max-width:136px;
      }
    }
    > div {
      padding:.5rem .9rem;
      @include breakpoint (medium) {
        padding:.5rem 1.5rem;
      }
    }
    &-specs {
      color:$white;
      font-weight: 600;
      @include breakpoint (small only) {
        background-color:$black;
        font-size:.7rem
      }
      span {
        @include breakpoint (medium) {
          color:$gray9;
        }
      }
    }
    &-image {
      @include breakpoint (small only) {
        background-color:$white;
      }
    }
  }
}

////// mobile ////////
.slick-dotted.slick-slider {
  margin-bottom:0;
}
.slick-dots {
  bottom:15px;
  li {
    width:10px;
    height:10px;
    button {
      width:10px;
      height:10px;
      &:before {
        color:transparent;
        background-color:$white;
        border-radius: 10px;
        width:10px;
        height:10px;
        opacity:.5;
      }
    }
    &.slick-active {
      button:before {
        color:transparent;
        opacity:.95;
      }
    }
  }
}
////// end - mobile //////






.hero-bkg {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  @include breakpoint(medium up) {
    background-size: cover;
  }
}

.hero-overlay {
  background-color: $primary-color;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1023px) {
      align-items: center;
      text-align: center;
    }
  }
  @include breakpoint(large up) {
    position: absolute;
    top: 20%;
    right: 0;
    padding: 0;
    background-color: transparent;
  }
  &-info {
    display: inline-block;
    background-color: rgba(0, 102, 178, 1);
    padding: 0.5rem;
    color: $white;
    max-width: 500px;
    width: 100%;
    @include breakpoint(medium up) {
      padding: 1rem 1rem 1rem 2rem;
    }
    @include breakpoint(large up) {
      margin: 0 0 1rem;
      background-color: rgba(0, 102, 178, 0.8);
      clip-path: polygon(6% 0, 100% 0%, 100% 100%, 0 100%);
      -webkit-clip-path: polygon(6% 0, 100% 0%, 100% 100%, 0 100%);
    }
    &--small {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      @include breakpoint(medium up) {
        font-size: 1.43rem;
      }
    }
    &--medium {
      font-size: 1.7rem;
      font-style: italic;
      @include breakpoint(medium up) {
        font-size: 2.4rem;
      }
    }
    &--large {
      color: $white;
      font-size: 2rem;
      font-style: italic;
      margin: 0;
      @include breakpoint(medium up) {
        font-size: 4.6rem;
      }
    }
  }
  &-header {
    font-family: 'Roboto Condensed';
    text-transform: capitalize;
    font-style: italic;
    font-size: 2rem;
    margin-bottom: 0;
    @include breakpoint(large up) {
      margin-top: 1rem;
      font-size: 3.2rem;
      margin-bottom: 0.5rem;
    }
  }
  &-desc {
    text-transform: capitalize;
    font-weight: 300;
    line-height: 1.2;
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 0;
    @include breakpoint(large up) {
      margin-bottom: 1rem;
    }
  }
}

.hero-btn {
  align-self: flex-start;
  @include breakpoint(medium up) {
    display: inline-block;
  }
  @media (max-width: 1023px) {
    margin: 0 auto 1rem;
  }
}