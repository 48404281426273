// wheel information and pricing
#package .wheel-info {
  padding:1rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 25px $grayC;
  margin-bottom:1rem;
  @include breakpoint (large) {
    padding:2.5rem;
    margin-bottom:2.5rem;
  }
  // information
  h1,h4 {
    font-size:1.5rem;
    text-transform: uppercase;
    letter-spacing:.2rem;
    font-weight:700;
    margin-bottom:.5rem;
    line-height:1.1;
  }

  .bottom,.pricing-stock,.pricing-price,.pricing-match {
    font-family:$detail-font-family;
  }

  .pricing-specs {
    color:$black;
    margin-bottom:1rem;
    .top {
      font-size:1.25rem;
      font-weight:700;
      text-transform: uppercase;
      letter-spacing:.1rem;
      margin-bottom:1rem;
      .front,.rear {
        > span {
          &:after {
            content:',';
          }
          &:last-child:after {
            content:'';
          }
        }
      }
    }
    .bottom {
      //display:flex;
      margin-bottom:.5rem;
      font-weight:500;
      > div {
      //  width:50%;
      }
      span {
        color:$black;
        font-weight:600;
        text-transform: capitalize;
      }
      .pricing-product {
        color:$black;
      }
      .pricing-brand {
        color:$black;
      }
      .wheel-info-specs, .tire-info-specs {
        line-height:1.7;
      }
    }
  }

  .pricing-stock {
    color:$green;
    text-transform: uppercase;
    font-weight:700;
    letter-spacing:.05rem;
    line-height:1.6;
    margin-bottom:1rem;
    .quantity {
      font-weight:700;
    }
  }

  .pricing-price {
    background-color:$grayEF;
    padding:1rem;
    border-radius:.5rem;
    color:$black;
    margin-bottom:1rem;
    line-height:1.7;
    .title {
      font-size:1.3rem;
      font-weight:600;
      margin-bottom:.5rem;
    }
    .subtitle {
      font-size:.9rem;
      font-weight:600;
      letter-spacing:.05rem;
      text-transform: none;
      span {
        text-transform: uppercase;
      }
    }
    .additional {
      font-size:1rem;
      color:$black;
      text-transform: uppercase;
      .title {
        margin-bottom:.75rem;
        font-size:1rem;
        text-align:center;
        i {
          color:$primary-color;
        }
      }
      &-list {
        ul {
          list-style: none;
          margin:0;
        }
        li {
          margin-bottom:.5rem;
        }
        span:not(.fas) {
          font-weight:400;
          text-transform: none;
        }
        .icon {
          height:28px;
          width:28px;
          border-radius:25px;
          color:$white;
          display:inline-flex;
          align-items:center;
          justify-content:center;
          &.pink {
            background-color:$pink;
          }
          &.green {
            background-color:$green2;
          }
        }
      }
    }
  }

  // affirm / financing
  .pricing-affirm {
    background-color:$grayEF;
    padding:1rem;
    border-radius:.5rem;
    color:$black;
    margin-bottom:1rem;
    font-size:.9rem;
    text-align:center;
    font-weight:500;
    .affirm-modal-trigger {
      display:inline-block;
      font-weight:600;
    }
    p {
      margin:0;
    }
  }

  // cta
  .pricing-match {
    margin-bottom:1rem;
    text-transform: capitalize;
    color:$black;
    font-size:.95rem;
    font-weight:600;
  }
  .cta {
    .button {
      background-color:$secondary-color;
      border-radius:.5rem;
      margin-bottom:0rem;
      width:100%;
      padding:1.6rem .5rem;
      font-size:1.05rem;
      &:hover {
        background-color:$grayC;
      }
      &.gray {
        background-color:$gray3;
        &:hover {
          background-color:$grayC;
        }
      }
    }
    &-button {
      margin-bottom:2rem;
    }
    &-link {
      text-transform: uppercase;
      margin-bottom:1.5rem;
      &:last-child {
        margin-bottom:0;
        .icon {
          background-color:$gray9;
        }
      }
      
      a {
        font-family:$detail-font-family;
        color:$gray3;
        font-weight:600;
        font-size:1rem;
        letter-spacing:.05rem;
        display:flex;
        align-items:center;
        justify-content: flex-start;
      }

      .icon {
        margin-right:1.5rem;
        width:50px;
        height:50px;
        display:flex;
        align-items:center;
        justify-content: center;
        border-radius:50px;
        background-color:$primary-color;
        text-decoration: unset;
        color:$white;
        font-size:2.5rem;
      }
      .text {
        text-decoration: underline;
        font-size:.85rem;
        @include breakpoint (large) {
          font-size:1rem;
        }
      }
    }
  }
  .buy-options {
    font-family:$detail-font-family;
    padding:1rem;
    background-color:$grayD4;
    border-radius:.5rem;
    label {
      font-weight:600;
    }
    .missing {
      background-color: rgba(255,51,51,.5);
    }
  }
}









#package .wheel-info {
  img {
    max-width:200px;
  }
  .series-logo {
    @include breakpoint (small only) {
      text-align:center;
      img {
        max-width:180px;
      }
    }
  }
  .wheel-name {
    font-size:2.25rem;
    font-weight:600;
    text-transform: uppercase;
    padding-bottom:.5rem;
    @include breakpoint (small only) {
      text-align: center;
      padding-bottom:0;
      font-size:2rem;
    }
    span {
      font-weight:400;
    }
  }
}

#see-on-vehicle-detail {
  h5 {
    font-weight:600;
    letter-spacing:-1px;
    line-height:1;
    color:$black;
  }
}


// spec information
.wheel-detail-sizes {
  .title {
    font-weight:600;
  }
}


// badge information
.wheel-badges {
  display:flex;
  > div {
    margin-right:.5rem;
  }
  img {
    max-width:100px;
  }
}


// finish information
.wheel-finishes {
  
  img {
    max-width:150px;
    transition: all .3s ease-in-out;
    &:hover {
      opacity:.5;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight:600;
    color:$gray3;
    margin-bottom:1.15rem;
  }
  .detail-thumb {
    display:flex;
  }
  .wheel-finish {
    text-align: center;
    font-weight:600;
    margin-right:.5rem;
  }
}


// videos information 
.wheel-videos {
  &.finishes-border {
    border-top: 1px solid $grayA;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    color: $gray3;
    margin-bottom: 1.15rem;
  }
  .wheel-video {
    display:flex;
    > div {
      width:100%;
      max-width:50%;
    }
    a {
      display:block;
      border:1px solid $grayA;
      position:relative;
      &:hover {
        .play-icon {
          background-color:rgba(0,0,0,0);
          img {
            opacity:0;
          }
        }
      }
    }
    .play-icon {
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      display:flex;
      justify-content:center;
      align-items:center;
      background-color:rgba(0,0,0,.5);
      transition: all .3s ease-in-out;
      img {
        transition: all .3s ease-in-out;
      }
    }
  }
}