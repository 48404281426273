#gallery-vehicle {
  padding-top:2rem;
  @include breakpoint (large) {
    padding-top:3.5rem;
    margin-bottom:4rem;
  }
  ///// page header /////
  .page-header {
    text-align:center;
    padding-top:1.25rem;
    padding-bottom:1.25rem;
    @include breakpoint (large) {
      padding-top:2rem;
      padding-bottom:2rem;
    }
    h1 {
      font-family:$heading-font-family;
      letter-spacing:.15rem;
      font-weight:700;
      margin-bottom:0;
      font-size:2rem;
      @include breakpoint (large) {
        font-size:3.5rem;
      }
    }
  }
  ///// gallery content /////
  .gallery-content {
    background-color:$white;
    float:left;
    box-shadow: 0px 0px 5px $grayB;
    padding-bottom:2rem;
    &-container {
      padding-bottom:4rem;
    }
    &-padding {
      float:left;
      border:1px solid $grayC;
      background-color:$grayE;
      padding:1.85rem;
      width:100%;
    }
    &.row {
      max-width:75rem;
      width:100%;
    }
  }
  
  ///// left /////
  .left {
    .gallery-image {
      padding-top:0;
      padding-bottom:1rem;
      @include breakpoint (large) {
        padding:1.75rem;
        padding-right:3rem;
        padding-top:0;
      }
      .slick-slider {
        margin-bottom:1.25rem;
      }
    }
    .spec-information-container, .gallery-description-container, .gallery-related-container {
      padding-left:.4rem;
      padding-right:.4rem;
      @include breakpoint (large) {
        padding-left:.9rem;
        padding-right:2.1rem;
      }
    }
    
    
    // spec information
    .spec-information {
      &-container {
        font-family:$heading-font-family;
        letter-spacing:.05rem;
        &-end {
          border-bottom:1px solid $gray9;
          padding-bottom:1rem;
          margin-bottom:2rem;
        }
      }
      &-title {
        color: $gray3;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .1rem;
        border-bottom: 1px solid #999999;
        margin-bottom: 1rem;
      }
      &-item {
        display: flex;
        font-size: .9rem;
        font-weight: 500;
        color: $gray3;
        //text-transform: uppercase;
        &:nth-child(odd) {
          > div {
            background-color:$grayE;
          }
        }
        > div {
          width:100%;
          padding: 0.5rem;
          margin: 0 2px;
          &:first-child {
            margin-left:0;
          }
          &:last-child {
            margin-right:0;
          }
        }
        .info-label {
          font-weight:700;
        }
      }
    }
    
    // description
    .gallery-description {
      &-divider {
        border-top:1px solid $grayC;
      }
      h4 {
        font-size: 1.5rem;
        color: $gray3;
        letter-spacing: .1rem;
        font-weight: 700;
        text-transform: uppercase;
      }
      p {
        font-size:.9rem;
        font-weight:500;
        line-height:2;
        color:$gray3;
      }
    }
    
    // related content
    .gallery-related {
      padding:1.5rem 0;
      &-title {
        background-color:$gray3;
        color:$white;
        padding:.6rem .75rem;
        font-family:$detail-font-family;
        text-transform: uppercase;
        line-height:1;
        font-size:1.35rem;
        font-weight: 700;
      }
    }

    // mobile
    .vehicle-details {
      display:none;
      @include breakpoint (medium) {
        display:block;
      }
      &-container {
        h4 {
          text-transform: uppercase;
          font-size:1.25rem;
          letter-spacing:.1rem;
          color:$gray3;
          margin-bottom:0rem;
          font-weight:700;
        }
        i {
          font-size:2rem;
          &.fa-minus,&.fa-plus {
            color:$gray8;
            &:hover {
              color:$primary-color;
            }
          }
        }
      }
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid $gray9;
        padding-bottom:.5rem;
        margin-bottom:2rem;
      }
    }
  }
  
  
  ///// right /////
  .right {
    text-transform: uppercase;
    .lower {
      text-transform: lowercase;
    }
    .package {
      padding:1rem;
      border-radius: 1rem;
      box-shadow: 0px 0px 25px $grayC;
      margin-bottom:1rem;
      font-family:$heading-font-family;
      @include breakpoint (large) {
        padding:2.5rem;
        margin-bottom:2.5rem;
      }
      // information
      h1,h4 {
        font-size:2rem;
        text-transform: uppercase;
        letter-spacing:.2rem;
        font-weight:700;
        margin-bottom:.5rem;
        line-height:1;
      }

      .product-name,.spec-information {
        text-align:left;
      }

      .spec-information {
        .brand,.style {
          font-size: 1.25rem;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: .1rem;
          color:$black;
        }
        .image-container {
          display:flex;
          align-items:center;
          font-size:1.5rem;
          font-weight:700;
          line-height:1;
          color:$blueDeep;
          > div {
            margin-right:2rem;
            &:last-child {
              margin-right:0;
            }
          }
        }
      }

      // cta
      .cta {
        .button {
          background-color:$blueDeep;
          border-radius:.5rem;
          margin-bottom:0rem;
          width:100%;
          padding:1.6rem .5rem;
          font-size:1.05rem;
          &:hover {
            background-color:$grayC;
          }
          &.gray {
            background-color:$gray3;
            &:hover {
              background-color:$grayC;
            }
          }
        }
        &-button {
          margin-bottom:2rem;
        }
        &-link {
          text-transform: uppercase;
          margin-bottom:1.5rem;
          &:last-child {
            margin-bottom:0;
            .icon {
              background-color:$gray9;
            }
          }
          
          a {
            font-family:$detail-font-family;
            color:$gray3;
            font-weight:600;
            font-size:1rem;
            letter-spacing:.05rem;
            display:flex;
            align-items:center;
            justify-content: flex-start;
          }
    
          .icon {
            margin-right:1.5rem;
            width:50px;
            height:50px;
            display:flex;
            align-items:center;
            justify-content: center;
            border-radius:50px;
            background-color:$blueDeep;
            text-decoration: unset;
            color:$white;
            font-size:2.5rem;
          }
          .text {
            text-decoration: underline;
          }
        }
      }
    }




    // pricing
    /*
    .pricing {
      font-family:$detail-font-family;
      font-weight:700;
      text-align: center;
      color:$gray3;
      line-height:1;
    }*/
    .image {
      margin-bottom:1.15rem;
    }
    .symbol {
      margin-top:5px;
      font-size:1rem;
    }
    .package-pricing {
      display:flex;
      align-items: center;
      justify-content: center;
      background-color:$gray3;
      color:$white;
      text-transform: uppercase;
      padding-top:1rem;
      padding-bottom:1rem;
      margin-bottom:1rem;
      .symbol {
        font-size:1.5rem;
        margin-top:-10px;
      }
      .value {
        font-size:1.75rem;
        @include breakpoint (large) {
          font-size:2.5rem;
        }
      }
    }
    .wheel-pricing,.tire-pricing {
      display:inline-block;
      padding:1rem;
      @include breakpoint (large) {
        padding:2rem;
        padding-top:1rem;
      }
      .price {
        font-size:1.5rem;
        display:flex;
        justify-content: center;
      }
      .subtitle {
        font-size:.85rem;
        letter-spacing:.05rem;
      }
    }
    
    
    // cta
    /*.cta {
      padding:0 1rem;
      margin-bottom:5rem;
      @include breakpoint (large) {
        padding-right:1.5rem;
        padding-left:0;
      }
      ul {
        list-style: none;
        margin:0;
        li:first-child a {
          background-color:$red;
          color:$white;
          font-weight:600;
        }
      }
      li {
        margin-bottom:1rem;
      }
      a {
        padding:1.25rem 0;
        color:$black;
        background-color:$grayE;
        box-shadow: 5px 5px 0 $gray6;
      }
    }*/
    
    // share
    .share {
      text-align:center;
    }

    // financing
    .financing {
      .row > {
        .column {
          margin-bottom:0.9375rem;
          &:nth-child(odd) {
            padding-right:.46875rem;
          }
          &:nth-child(even) {
            padding-left:.46875rem;
          }
        }
      }
    }
  }
  
  
  // bottom 
  .quote {
    float:left;
    padding-top:2rem;
    padding-bottom:0;
    width:100%;
    .column,.columns {
      padding:0 1rem;
    }
  }
}


