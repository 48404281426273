#tire {
  padding-top:2rem;
  @include breakpoint (large) {
    padding-top:3.5rem;
    margin-bottom:4rem;
  }
}

///// left /////////////
#tire {
  .about-container {
    h4,p,.tire-sizes {
      color:$gray3;
    }
    h4 {
      font-size:1.5rem;
      color:$gray3;
      letter-spacing:.1rem;
      font-weight:700;
      text-transform: uppercase;
    }
    p {
      font-size:.9rem;
      font-weight:500;
      line-height:2;
    }
    .wheel-sizes {
      font-size:1rem;
      font-weight:600;
      margin-bottom:1rem;
    }
  }
}

///// right /////////////











#tire {
  #detail-top {
    margin-top:1rem;
    margin-bottom:2rem;
    @include breakpoint (medium) {
      margin-top:2rem;
    }
  }
  
  .details-info-section {
    border-bottom:1px solid $grayA;
    margin-bottom:1.5rem;
    padding-bottom:1.5rem;
    &.wheel-dealers {
      padding-top:1.5rem;
      .button {
        width:100%;
        background-color:$black;
        &:focus {
          background-color:$primary-color;
        }
        &:hover {
          background-color:$gray9;
        }
      }
    }
  }
  
  
  
/*  .wheel-sizes {
    margin-top: 1rem;
    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }*/
  .tire-details {
    background: #f1f1f1;
    padding: 1rem 1.5rem;
    .tire-paragraph {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  .loadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }
}

.gallery-popup {
  display: table;
  margin: 0 auto;
  .galleryView-info {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    span {
      display: block;
    }
  }
  .popup-car {
    font-weight: 900;
    font-size: 1.3rem;
    color: #fff;
  }
  .mfp-close {
    color: white !important;
    position: relative;
    float: right;
  }
}

@include breakpoint(medium up) {
  .accordion-content {
    padding: 0;
    border: 0;
    border-bottom: 0 !important;
  }
  .alt-images {
    .slick-slide {
      /*width: 100% !important;*/
    }
  }
}

.detail-thumb {
  p {
    line-height: 1;
    margin: 0.25rem;
  }
  .finish-name {
    color: $dark-gray;
    font-size: 0.8rem;
  }
  img {
    transition: transform 0.3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}

.see-on-vehicle-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.2rem;
  margin: 0.5rem 0;
}

.see-on-vehicle-header {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.see-on-vehicle-select {
  margin-bottom: 0;
}

table {
  border: 0;
  margin-bottom: 0;
  tr {
    td,
    th {
      text-align: center;
    }
    th {
      background: $light-gray;
    }
  }
}

@include breakpoint(small only) {
  #tire {
    .banner-top {
      .tire-banner {
        height: 6rem;
      }
      .heading {
        font-size: 1.7rem;
        top: 90%;
      }
    }
    .slick-slide {
      display: inline-block;
      float: none;
    }
  }
}

@include breakpoint(medium up) {
  .finish-header {
    display: none;
  }
}

.tire-warning {
  margin-bottom: 2rem;
}

#tire {
  .financing {
    padding-left:.625rem;
    padding-right:.625rem;
    .row > {
      .column {
        margin-bottom:0.9375rem;
        &:nth-child(odd) {
          padding-right:.46875rem;
        }
        &:nth-child(even) {
          padding-left:.46875rem;
        }
      }
    }
  }
}