
#error-page {
  margin-bottom:6rem;
  // header
  h1 {
    text-transform:uppercase;
    color:$black;
    @include breakpoint (large) {
      font-size:2.25rem;
      font-weight:700;
      letter-spacing:.2rem;
    }
  }
  .content-header {
    margin-bottom:2rem;
    background-color:$gray6;
      background-size:cover;
      background-position: center;
      text-align:center;
      
      display:flex;
      align-items: center;
      justify-content: center;
      margin-bottom:2rem;
      padding:0;
    @include breakpoint (large) {
      height:240px;
    }
    h1 {
      color:$white;
    }
    .content-header-overlay {
      background-color:rgba(0,0,0,.5);
      padding-top:2rem;
      padding-bottom:2rem;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: center;
      height:100%;
    }
  }

  // content
  .page-content {
    background-image:url('/images/utilities/error-bg.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    h2 {
      font-size:1.5rem;
      font-weight:600;
    }
    h3 {
      font-weight:700;
    }
    p {
      font-weight:500;
      margin-bottom:2rem;
      color:$black;
      font-size:1.15rem;
      a {
        font-weight:700;
      }
    }
    
    ul {
      list-style: none;
      margin-left:0;
      margin-bottom:2rem;
    }
    .button {
      max-width:260px;
      width:100%;
    }
    .icon {
      margin-bottom:2rem;
    }
    .title {
      margin-bottom:2rem;
      @include breakpoint (large) {
        margin-bottom:4rem;
      }
      h2 {
        font-size:1.85rem;
        font-weight:700;
      }
    }
  }
  
}
