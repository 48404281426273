$red:#fe3232;

#snipcart {
  /*///// checkout /////*/
  input {
    margin-bottom:0;
  }
  .snipcart-input {
    height:auto;
  }
  .im-custom-subtitle {
    margin-top:2rem;
  }

  .address-disclaimer {
    margin-top:1rem;
    p {
      font-weight:600;
      color:$red;
    }
  }

  .snipcart-featured-payment-methods__list {
    align-items:center;
    justify-content: center;
    &-item {
      padding:8px;
    }
  }
}

.snipcart-form-radio__label {
  display: flex!important;
}
