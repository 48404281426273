.hp .cta {
  padding:2rem 0;
  @include breakpoint (large) {
    padding:5rem 0;
  }
  h2 {
    font-size:1.5rem;
    line-height:1;
    letter-spacing:0;
    font-weight:900;
    @include breakpoint (large) {
      font-size:2.5rem;
    }
  }
  .section-title {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:5rem;
    }
  }


  a {
    text-align:center;
    display:flex;
    flex-direction: column;
    align-items:center;
    margin-bottom:2rem;
    .content {
      padding:1rem 0;
      border:1px solid $grayED;
      box-shadow: 0 0 5px $grayC;
      border-radius:15px;
      max-width:400px;
      width:100%;
      height:300px;
      margin-bottom:1rem;
      transition: all .3s ease-in-out;
      img {
        transition: all .3s ease-in-out;
        width:auto;
        @include breakpoint (medium) {
          height:100%;
          max-width:none;
        }
      }
    }
    .text {
      font-family:$heading-font-family;
      color:$black;
      text-transform: uppercase;
      font-weight:500;
      .title {
        font-weight:900;
        display:inline-block;
        color:$blueDeep;
        border-bottom:4px solid $primary-color;
        font-size:2rem;
        width:160px;
        margin-bottom:1rem;
      }
    }
    &:hover {
      .content {
        scale:1.05;
        img {
          scale:1.05;
        }
      }
    }
  }
}