#tire {
  .tire-detail-stage {
    position: relative;
    margin-bottom:3rem;
    @include breakpoint (large) {
      margin-bottom:7rem;
    }
  }
  



  .stage-container {
    @include breakpoint (medium) {
      display:flex;
      justify-content: center;
      flex-direction:row-reverse;
    }
    > div {
      &:last-child {
        @include breakpoint (large) {
          width:15%
        }
      }
      &:first-child {
        @include breakpoint (large) {
          width:85%;
        }
      }
    }
    .alt-images {
      .slick-slide {
        @include breakpoint (medium) {
          width:100%!important;
        }
      }
      .slick-track {
        @include breakpoint (medium) {
          width:100%!important;
        }
      }
    }
  }
}



.main-image {
  margin-bottom: 0;
  img {
    margin: 0 auto;
  }
}

.alt-images {
  
  margin:0 auto 2rem;
  max-height:400px;
  @include breakpoint (large) {
    margin-bottom: 0 !important;
    max-width:60%;
  //  .slick-track {
    //height:400px;
    //overflow-y:scroll;
    //}
  }
  .slick-track {
    margin: 0 auto;
  }
  &--retailer {
    @include breakpoint(medium up) {
      .slick-track {
        /*width: 100% !important;*/
        /*margin-top: 3rem;*/
      }
    }
  }
  .slick-list {
    @include breakpoint (large) {
      padding:35px 0!important;
    }
  }
  @include breakpoint(small) {
    .slick-dots {
      position: relative !important;
      text-align: center;
      bottom: 0;
      margin: 0;
    }
  }
  .slick-slide {
    &:focus {
      outline: 0;
    }
    img {
      max-width:100px;
      margin:0 auto;
      width:100%;
    }
  }
  a {
    margin-right: 0;
    padding: .7rem;
  }
  img {
    transition: all .3s ease-in-out;
    opacity:.5;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
  .slick-active {
    &:after {
      width:0;
      transition: all .3s ease-in-out;
      content:'';
      display:block;
      margin:0 auto;
      height:5px;
      background-color:$primary-color;
    }
  }
  .slick-current {
    &:after {
      width:80%;
    }
    img {
      opacity: 1;
    }
  }
}

// zoom over image function
.zoom {
  display:inline-block;
  position: relative;
  &:after {
    content:'';
    display:block; 
    width:33px; 
    height:33px; 
    position:absolute; 
    top:0;
    right:0;
    background:url(icon.png);
  }
  img {
    display:block;
      &::selection {
        background-color:transparent;
      }
  }
}