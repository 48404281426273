.column-filter {
  @include breakpoint (large) {
    padding-left:0;
    padding-right:0;
  }
}
.products-filter {
  border:1px solid $gray3;
  padding:1rem;
  margin-bottom:3rem;
  input {
    margin-bottom:0;
  }
  h4 {
    text-transform: uppercase;
    margin-bottom:0;
    font-family:$body-font-family;
    font-size:1.5rem;
    font-weight:800;
  }
  .filter-section {
    border-bottom:1px solid $gray3;
    margin-bottom:1rem;
    padding-bottom:1rem;
    &:last-child {
      border-bottom:0;
      padding-bottom:0;
      margin-bottom:0;
    }
    &.filter-vehicle {
      a {
        margin-left:1.6rem;
        color:$black;
        font-weight:400;
        &.active {
          color:$primary-color;
          font-weight:600;
        }
        &:hover {
          color:$primary-color;
          font-weight:800;
        }
      } 
    }
  }
  .filter-title {
    display:flex;
    justify-content: space-between;
    align-items: center;
    i {
      color:$gray4;
      font-size:1.65rem;
    }
  }
  .filter-items {
    ul {
      list-style: none;
      &.level-0 {
        margin-left:0;
      }
    }
  }
  .filter-item {
    label {
      margin-right:0;
    }
  }
  
  
  // subcategories and subsubcategories
  .filter-category {
    label {
      &.checked {
        font-weight:800;
      }
    }
    .category-sub,.category-filter {
      margin-left:1rem;
    }
    .category-sub {
      label.checked {
        color:$primary-color;
      }
    }
    .category-filter {
      label.checked {
        color:$primary-color;
      }
    }
  }
  
  // clear filter
  .clear {
    color:$gray3;
    font-weight:800;
    font-size:.8375rem;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      color:$primary-color;
    }
  }
  
}

// price dropdown
.header-price-filter,.header-sort-filter {
  padding-bottom:1rem;
  text-align: right;
  @include breakpoint (medium only) {
    padding-right:1rem;
  }
  @include breakpoint (medium) {
    padding-top:2rem;
    padding-bottom:2rem;
  }
  select {
    margin-bottom:0;
    color:$gray3;
    text-transform: uppercase;
    font-weight:700;
    font-family:$body-font-family;
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 204, 0%29'></polygon></svg>");
    @include breakpoint (medium) {
      max-width:210px;
    }
  }
}

// search by sku / part number
.products-search {
  input {
    border-color: $gray3;
    border-right:0;
  }
  button {
    width:100%;
    padding:.575rem;
    border:1px solid $gray3;
    border-left:0;
  }
  form {
    margin-top:1rem;
  }
}