.hp .packages {
  padding:2.5rem;
  @include breakpoint (large) {
    padding:3rem 0;
    padding-bottom:6rem;
  }
  h6 {
    font-family:$body-font-family;
    font-weight:900;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
  }
  h2 {
    font-family: $heading-font-family;
    position: relative;
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    line-height:1;
    letter-spacing:0;
    font-weight:900;

    @include breakpoint(large up) {
      font-size: 2.5rem;
      padding-bottom:1rem;
      margin-bottom:2rem;
      line-height:1.4;
    }
/*
    &:after {
      content: '';
      position: absolute;
      border-bottom: 2px solid $primary-color;
      bottom: 0;
      width: 100%;
      max-width:364px;
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint(large up) {
        width: 40%;
      }
    }*/
  }

  &-list {
    a {
      position:relative;
      img {
        transition: all .3s ease-in-out;
      }
      &:hover {
        h6 {
          color:$primary-color;
        }
        img {
          transform:scale(1.05);
        }
        .pkg-info-description {
          color:$primary-color;
        }
      }
    }
    .pkg-special {
      background-color:$primary-color;
      color:$black;
      font-family: $heading-font-family;
      text-transform: uppercase;
      padding:.5rem 1rem;
      font-weight:800;
      border-radius:25px;
      position: absolute;
      top:1rem;
    }
  }

  &-container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-items: center;

    @include breakpoint(large up) {
      grid-template-columns: repeat(5, 20%);
    }
  }

  &-button-container {
    padding-top: 2em;
    
    .button {
      max-width:260px;
      width:100%;
      background-color:$gray3;
      border-radius:3px;
      @include breakpoint (medium down) {
        font-size:1rem;
        max-width:200px;
      }
      &:hover {
        background-color:$primary-color;
      }
    }
  }

  .pkg-img {
    position: relative;
    margin-bottom:2rem;
    &-wheel {
      width: 80%;
      margin-left: auto;
      display: block;
    }
    &-tire {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 55%;
    }
  }
  
  .pkg-info {
    margin-bottom: 1rem;
    line-height:1.35;
    &-header,&-description {
      font-size: .9rem;
      font-weight:700;
      text-transform: uppercase;
      //letter-spacing: .1rem;
      line-height:1;
      @include breakpoint (large) {
        line-height:1.4;
      }
    }
    &-header {
      font-size:1.25rem;
      font-weight:700;
      line-height:1.1;
      color:$primary-color;
    }
    &-description {
      text-align:center;
      color:$black;
      transition: all .3s ease-in-out;
    }
    &-wheel,
    &-tire {
      font-weight: 400;
      color: $black;
    }
    &-price {
      font-weight: 700;
      color: $black;
    }
    .red {
      color: $primary-color;
    }
    .description {
      text-align:center;
      margin-bottom:.5rem;
      .information {
        color:$black;
        font-family:$heading-font-family;
        font-weight:800;
        .spec-info,.size {
          color:$black;
          font-weight:500;
        }
      }
    }
    .price {
      text-align:center;
      font-family:$heading-font-family;
      font-weight:800;
      color:$blueDeep;
      font-size:1.25rem;
    }
  }
  
  .pkg-btn {
    width: 75%;
  }
  
}