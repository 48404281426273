.videos {
  margin-bottom:3rem;
  .video-item {
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:0;
    }
  }
  a {
    position:relative;
    display:block;
    font-size:2.75rem; 
    .video-icon {
      position: absolute;
      top:0;
      right:0;
      left:0;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content: center;
      background-color:rgba(0,0,0,.3);
      i {
        color:$white;
        background-color:$red;
        border-radius:50px;
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
  }
}