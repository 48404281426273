/* ==========================================================================
   2. Header
   ========================================================================== */
header {
  background-color:$white;
  .top-bar {
    padding: 21px 0;

    a {
      font-size: 1rem;
      font-weight: 700;
      color: $black;
      text-transform: uppercase;
      position: relative;
      &.active-page {
        &:before {
          content:'';
          position:absolute;
          right:0;
          left:0;
          bottom:-.6rem;
          display:block;
          background-color:$green;
          height:3px;
          width:100%;
        }
      }
    }

    .top-bar-left {
      padding: 8px 0;
      padding-right: 2%;
    }

    .hp-btn-links a {
      font-size: 1.2rem;
      padding-left: 1.8rem;
      padding-right: 1.8rem;
      margin-left: 8px;
    }
  }

  .top-bar-title {
    background-color:$white;
    img {
      max-width: 350px;
    }
    /*
    .header-logo {
      background-color:$black;
    }*/
  }
/*  .header-logo {
    width: 200px;
    position: relative;
    height: 1px;
    top: -5px;

    a {
      position: absolute;
      z-index: 10;
    }
  }*/



  .header {
    display:flex;
    max-width:90rem;
    justify-content: space-between;
    align-items: center;
    @include breakpoint (medium) {
      padding-left:0.9375rem;
      padding-right:0.9375rem;
    }
    
    @include breakpoint (large) {
      flex-direction:row;
      margin:0 auto;
      padding-top:.5rem;
      padding-bottom:.5rem;
    }
    > span:first-child {
      width:28%;
    }
    .header-logo {
      max-width:100px;
      @include breakpoint (large) {
        max-width:none;
      }
    }
    #responsive-menu {
      display:flex;
      align-items:center;
      width:100%;
      @include breakpoint (large) {
        width:65%;
      }
      > .top-bar-right {
        width:100%;
      }
      ul {
        //display:flex;
        flex-direction: column;
        padding:1rem 2rem;
        @include breakpoint (large) {
          justify-content: space-evenly;
          flex-direction: row;
          padding:0;
          ul {
            //padding:1rem;
            padding:2rem 2.9375rem;
            list-style: none;
            
            margin-left:0;
            right: auto;
            min-width:300px;

            ul {
              margin-right:1rem;
              //border:0;
              //background-color:rgba(255,255,255,.9);
              background-color:$white;
              left:123%;
              min-width:300px;
              margin-top:-2.05rem;
            }
          }
        }
      }
      a {
        font-family:$heading-font-family;
        text-transform: uppercase;
        color:$white;
        font-weight:700;
        letter-spacing:.05rem;
        background-color:transparent;
        width:100%;
        padding-left:0;
        padding-right:0;
        @include breakpoint (large) {
          color:$black;
          //font-size:.85rem;
        }
        &:hover {
          color:$primary-color;
        }
      }
      .header-mobile-only {
        margin-top:1rem;
        padding-top:1rem;
        border-top:1px solid $white;

        @include breakpoint (large) {
          display:none;
        }
        .button {
          width:100%;
        }
        ul {
          flex-direction:row;
          a {
            margin-right:2rem;
            font-size:1.5rem;
          }
        }
      }
    }
    .nav-menu-right {
      width:30%;
      display:flex;
      align-items:center;
      justify-content: space-between;
      ul {
        margin:0;
        li {
          display:inline-block;
          border-left:1px solid $black;
          padding-left:1.5rem;
          margin-left:1rem;
        }
        &.header-cart-links {
          display:flex;
          align-items:center;
          a {
            font-size:1.5rem;
            font-weight:100;
            color:$black;
            padding-right:1rem;
            position: relative;
            &:hover {
              color:$primary-color;
            }
            .snipcart-items-count {
              position: absolute;
              display:flex;
              top:-6px;
              //left:0;
              //bottom:0;
              right:12px;
              color:$white;
              font-weight:800;
              font-size:.8rem;
              width:20px;
              height:20px;
              text-align:center;
              justify-content:center;
              align-items: center;
              background-color:$red;
              border-radius:50px;
            }
            &.snipcart-checkout {
              i {
                font-size:1.75rem;
                color:$black;
              }
            }
          }
        }
        &.header-custom-quote, &.header-cart-links {
          a {
            display:flex;
            color:$black;
            font-family:$heading-font-family;
            line-height:1.15;
            text-transform: uppercase;
            font-weight:700;
            font-size:.8rem;
            i {
              font-size:1.5rem;
              color:$secondary-color;
              margin-left:.5rem;
            }
          }
        }
        &.header-custom-quote {
          display:flex;
          align-items: center;
        }
      }
      .button {
        font-size:.75rem;
        margin-bottom:0;
        letter-spacing:.05rem;
        border-radius:3px;
        background-color:$primary-color;
        width:220px;
        &:hover {
          background-color:$gray6;
        }
      }

      // mobile
      &-mobile {
        width:28%;
        > a {
          padding:.5rem;
        }
      }
    }

    .top-bar-left {
      display:flex;
      width:100%;
      justify-content: space-between;
      align-items: center;
      padding:.75rem;
      background-color:$white;
      @include breakpoint (large) {
        width:auto;
        padding:0;
      }
      > span:first-child {
        width:28%;
      }
      .nav-menu-right-mobile {
        width:28%;
        > a {
          color:$blueDeep;
          padding:.5rem;
          font-size:1.75rem;
          &.snipcart-checkout {
            position:relative;
            i {
              font-size:1.75rem;
              color:$black;
            }
            .snipcart-items-count {
              position: absolute;
              display:flex;
              top:3px;
              right:0;
              color:$white;
              font-weight:800;
              font-size:.8rem;
              width:20px;
              height:20px;
              text-align:center;
              justify-content:center;
              align-items: center;
              background-color:$red;
              border-radius:50px;
            }
          }
        }
      }
    }
    .top-bar-right {
      @include breakpoint (large) {
        display:flex;
        justify-content: space-between;
      }
    }








    &-left {
      background-color:$white;
      //background:linear-gradient(104deg, $black0 0, $black0 85%, transparent 85%, transparent 100%);
      
      display:flex;
      align-items:center;
      justify-content:space-between;
      position:relative;
      padding:1rem;
      @include breakpoint (large) {
        background:linear-gradient(104deg, $black0 0, $black0 85%, transparent 85%, transparent 100%);
        width:222px;
        justify-content:center;
      }
      &:after {
        content:'';
        position: absolute;
        top:0;
        left:0;
        right:0;
        background-color:$primary-color;
        height:45px;
        z-index:-1;
      }
      > span,div {
        width:33.3333%;
        @include breakpoint (large) {
          width:auto;
        }
      }
      .header-logo {
        //background
        text-align:center;
        img {
          max-width:45px;
          @include breakpoint (large) {
            width:auto;
            max-width:none;
          }
        }
      }
    }
    &-right {
      width:100%;
      @include breakpoint (large) {
        height:126px!important;
      }
      &-top {
        background-color:$primary-color;
        .column {
          display:flex;
          align-items:center;
          &:nth-child(2) {
            text-align:center;
            a {
              margin-left:10%;
              font-size:2.5rem;
              line-height:1;
              font-weight:900;
            }
          }
          &:last-child {
            text-align:right;
            a {
              line-height:1;
            }
          }
        }
        a {
          font-weight:700;
          color:$white;
          font-size:.7rem;
          letter-spacing:.05rem;
          &:hover {
            color:$black;
          }
          &.snipcart-customer-signin {
            color:$black;
            &:hover {
              color:$white;
            }
          }
        }
        .pencil-header {
          display:flex;
          align-items:center;
          @include breakpoint (large) {
            padding-right:1.5rem;
          }
          > div {
            width:100%;
          }
        }
      }
      &-bottom {
        display:flex;
        justify-content:space-between;
        
        
      }
    }
  }
  .pencil-header-mobile {
    text-align:center;
    font-weight:800;
    background-color:$primary-color;
    display:flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $heading-font-family;
    a {
      font-family: $heading-font-family;
      color:$black;
      font-weight: 800;
      font-size: .875rem;
      letter-spacing: .05rem;
      text-transform: uppercase;
      margin-top:.25rem;
      &.header-phone {
        //font-size:1.5rem;
        //margin-bottom:.5rem;
        //margin-top:.25rem;
        //color:$black;
        //background-color:$white;
      }
    }
    .divider {
      width:2px;
      height:16px;
      background-color:$black;
      display:block;
      margin:10px 7px;
    }
  }
  .menu-icon {
    font-size:1.75rem;
    color:$black;
    &.menu-open {
      font-size:1rem;
    }
  }
  .header-cta-mobile {
    ul {
      margin:0;
      display:flex;
      list-style: none;
      justify-content:flex-end;
    }
    a {
      color:$black;
      padding:.5rem;
      &:hover {
        color:$primary-color;
      }
    }
  }
}

/* mobile */
.menu-icon.dark {

  &:hover:after,
  &:after {
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
  }
}

/* Media Queries */

@media screen and (max-width: 480px) {
  .menu>li:not(.menu-text)>a {
    padding: 0.7rem 0.5rem;
  }

  header .top-bar .top-bar-left {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  header {
    padding: 0;

    .menu.nav-social {
      display: block;
    }

    .top-bar-title {
      width: 100%;
      
/*      padding-right: 15px;
      padding-left: 15px;*/

      span {
        float: right;
      }

      img {
        width: 60%;
        margin-top: -10px;
      }
    }

    .top-bar {

      .top-bar-left,
      .top-bar-right {
        border-right: 0px;
        width: 100%;
      }

      .top-bar-right {
        padding: 10px 20px 20px;
      }

      .top-bar-left {
        li {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #eeeeee;
        }

        ul ul div div {
          border-right: 0;
          margin: 0;

          &:last-child {
            border: none;
          }
        }

        .dropdown.menu .submenu li {
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  header.fixed {
    position: inherit;
  }

  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }

  .menu>li {
    display: inherit;
  }

  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}

@media only screen and (max-width: 1212px) {
  header .top-bar a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1262px) {
  header .top-bar .top-bar-left {
    padding-right: 0;
  }
}

/* ==========================================================================
   3. Top bar
   ========================================================================== */

.sticky-container {
  z-index: 10;
  height:131px;
  @include breakpoint (large) {
    height:144px;
  }
}

header.main {
  box-shadow: 0px 5px 15px -5px black;
  position: relative;
  z-index: 10;
}

.basket-icon {
  color: $secondary-color;
  padding-right: 2px;
}

header {

  /*/// Pencil Navigation ///*/
  .pencil-header {
    height: 35px;
    font-size:.75rem;
    background-color:$primary-color;
    padding:.45rem 0;
    p {
      font-family:$heading-font-family;
      font-weight:700;
      color:$black;
      font-size:.9rem;
      margin-bottom:0;
      a {
        color:$black;
      }
      .header-phone {
        margin-left:.25rem;
      }
      .divider {
        margin:0 1rem;
      }
    }
    .row > .column {
      text-align:center;
      &:first-child {
        text-align:left;
      }
      &:last-child {
        text-align:right;
      }
    }
  }
  .pencil-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;
    .pencil-top-login {
      margin-left:2rem;
      padding:.65rem;
      padding-top:1rem;
      background-color:$black;
      a {
        color:$white;
        font-weight:700;
        &:hover {
          color:$primary-color;
        }
      }
    }
    .pencil-top-search {
      padding-bottom:0rem;
      margin-left:1rem;
      form {
        display:flex;
        align-items: center;
      }
      input {
        margin-bottom:0;
        height:1.25rem;
        max-width:200px;
        padding:0;
        box-shadow: 0 0 5px $grayC;
        //margin-right:1rem;
      }
      button {
        width:65%;
        text-transform: uppercase;
      }
    }
  }

  .pencil-top-announcement {
    color: white;
    height: 100%;
  }
/*
  .pencil-top-menu .menu>li:not(.menu-text)>a {
    text-transform: uppercase;
    color: $medium-gray;
    font-family: $body-font-family;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.5 !important;
    margin: 0;
    padding: 1rem 0.8rem;

    @media (min-width: 1024px) and (max-width: 1200px) {
      padding: 1rem 0.3rem;
    }

    &:hover {
      color: white;
    }
  }

  .pencil-top-announcement {
    font-family: $font-primary;
    padding: 8px 0;
    white-space: nowrap;
  }
*/
  .announcement-large {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;

    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 1.1rem;
    }
  }

  .announcement-small {
    font-size: 1rem;
    font-weight: 500;

    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 0.9;
    }
  }

  /*/// Main Navigation ///*/
  .top-bar {
    padding:0;
    @include breakpoint (medium) {
      padding: 15px;  
    }
    /*background-color: $white;*/

    @media only screen and (min-width: 64rem) {
      padding: 0;
      padding-bottom:1.5rem;
    }

    @media only screen and (max-width: 64rem) {
      .top-bar-right {
      }
    }

    .menu.icon-top>li>a {
      color: $dark-gray;

      @media screen and (max-width: 64rem) {
        text-align: left;
        font-size: 1.3rem;
        padding: 0.5rem;

        i {
          display: none;
        }
      }
    }

    .first-sub {
      left: 0 !important;
      right: 0 !important;
      top: 44px !important;
    }

    .header-logo {
      @media only screen and (max-width: 64rem) {
        top: 0;
      }
    }
    
    #responsive-menu {
      @include breakpoint (medium) {
        display:flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      
    }
  }
}

// dropdown menu
.menu {
  position:static;
}
/*
.dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
  list-style: none;
  margin:0;
  border-top:0;
  //background-color:rgba(255,255,255,.9);
  background-color:$white;
  padding:1rem;
  //min-width:205px;
  width:100%;
  max-width:75rem;
  left:0;
  right:0;
  margin:0 auto;
  box-shadow: 3px 1px 5px $gray6;
  @include breakpoint (large) {
    //top:50px;
    top:131px;
    left:0;
    border:0;
  }
//  &:before {
//    content: '';
//    height: 100vh;
//    background: rgba(0,0,0,.7);
//    position: absolute;
//    top: 0;
//    right: 0;
//    left: 0;
//    bottom: 0;
//    width: 100%;
//    max-width: none;
//    width: 100vw;
//    z-index: -1;
//    margin: 0 auto;
//    top: -5px;
//    left: -100%;
//    right: -100%;
//  }
  &:after {
    content: '';
    background-color:$white;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }
  h5 {
    text-transform: uppercase;
    font-weight:700;
    letter-spacing:.1rem;
    font-size:1.5rem;
  }
  button {
    font-size: 3rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
    font-weight:100;
  }
}*/
.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent {
  position:static;
}
.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > ul {
  list-style:none;
}
.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
  padding-right:0.1rem;
  &:after {
    display:none;
  }
}
.dropdown.menu > li > ul li a {
  display:block;
  font-weight:600;
  padding-left:.75rem;
  padding-right:.75rem;
  line-height:1;
}
.dropdown.menu > li:last-child .first-sub,.dropdown.menu > li:nth-child(7) .first-sub {
  @media only screen and (max-width:1360px) {
    left:auto!important;
  }
}
// dropdown menu - dropdown menu 
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right:0;
  left:auto;
  //border-right-color:$orange;
  border-left-color:$orange;

  border-left-style: solid;
  border-right-width: 0;
  border-left-width:6px;
}
header .menu>li:not(.menu-text)>a {
  padding: 0.1rem;
  margin: 0 0.6rem;

  @media only screen and (max-width: 64rem) {
    margin-left: 0;
  }
}

/* ==========================================================================
   2. Header
   ========================================================================== */
/* mobile */
.menu-icon {
  height:auto;
  &:hover:after,
  &:after {
    display:none;
  }
  font-weight:700;
  text-transform: uppercase;
  width:auto;
  font-size:1rem;
  transition:all .2s ease-in-out;
  &:hover {
    color:$gray57;
  }
}

header .top-bar .header-mobile-only {
  display:none;
  margin-left:0;
  padding-top:2rem;
  margin-top:2rem;
  border-top:1px solid $white;
  a {
    padding-top:.75rem;
    padding-bottom:.75rem;
    color:$white;
    display:block;
  }
}

.menu-open {
  /*&.sticky-container {*/
  /*  background-color:$black;*/
  /*}*/
  header {
    .top-bar {
      padding:0;
      &-title {
        background-color:$black;
      }
    }
    
    .menu-icon {
      color:$black;
      &:hover {
        color:$primary-color;
      }
    }
    nav {
      background-color:rgba(0,0,0,.9);
      //height:100vh;
      margin-left:0;
      display:block;
      
      
      .top-bar-right {
        //height:100vh;
        .vertical-rule-left {
          width:100%;
        }
        .is-drilldown {
          width:100%;
          max-width:none!important;
          height:100vh;
          //padding:1rem 2rem;
          &-submenu.is-active {
            background-color:rgba(0,0,0,1);
            margin-left:0;
            padding:1rem 2rem;
            a {
              color:$white;
              padding-top:.75rem;
              padding-bottom:.75rem;
              display:block;
              line-height:1;
            }
            .js-drilldown-back {
              border-bottom:1px solid $white;
              padding-bottom:1rem;
              margin-bottom:1rem;
              > a::before {
                border-color:transparent $white transparent transparent;  
              }
            }
          }
          &-submenu-parent {
            > a::after {
              border-color:transparent transparent transparent $white;
            }
          }
        }
        .menu > li:not(.menu-text) > a {
          color:$white;
          padding-top:.75rem;
          padding-bottom:.75rem;
          margin:0;
        }
        .header-mobile-only {
          display:block;
        }
      }
    }
  }
}


/* Media Queries */

@media screen and (max-width: 480px) {
  .menu>li:not(.menu-text)>a {
    padding: 0.7rem 0.5rem;
  }

  header .top-bar .top-bar-left {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1023px) {
  header {
    padding: 0;

    .menu.nav-social {
      display: block;
    }

    .top-bar-title {
      width: 100%;
/*      padding-right: 15px;
      padding-left: 15px;*/
      padding:15px;

      span {
        float: right;
      }

      img {
        width: 60%;
        margin-top: -10px;
      }
    }

    .top-bar {

      .top-bar-left,
      .top-bar-right {
        border-right: 0px;
        width: 100%;
      }

      .top-bar-right {
        padding: 10px 20px 20px;
      }

      .top-bar-left {
        li {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #eeeeee;
        }

        ul ul div div {
          border-right: 0;
          margin: 0;

          &:last-child {
            border: none;
          }
        }

        .dropdown.menu .submenu li {
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  header.fixed {
    position: inherit;
  }

  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }

  .menu>li {
    display: inherit;
  }

  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}

@media only screen and (max-width: 1212px) {
  header .top-bar a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1262px) {
  header .top-bar .top-bar-left {
    padding-right: 0;
  }
}




/////////// header nav search ////////////////////
.header-nav-search {
  background-color:$grayE7;
  width:100%;
  display:none;
  border-bottom:1px solid $grayC;
  @include breakpoint (large) {
    border-bottom:0;
  } 
  button {
    cursor: pointer;
    color:$black;
  }
  &-container {
    display:flex;
    justify-content: space-between;
    padding:1rem;
    @include breakpoint (medium) {
      padding-left:1.9375rem;
      padding-right:1.9375rem;
    }
  }
  .toggle-search {
    font-size:2rem;
  }
  form {
    display:flex;
    input {
      font-size:.75rem;
      font-weight:700;
      text-transform: uppercase;
      margin-bottom:0;
      width:280px;
      @include breakpoint (large) {
        width:400px;
      }
      &::placeholder {
        letter-spacing:.15rem;
        text-align:center;
      }
    }
    button {
      padding-left:.75rem;
      @include breakpoint (medium) {
        padding:0 1rem;
      }
    }
  }
}
.header-search {
  select {
    margin-bottom:0;
    max-width:100px;
  }
}
///// mega menu ////
.megamenu-container {
  //background-color:rgba(0,0,0,.7);
  position: absolute;
  //width:100%;
  left:0;
  right:0;
  //height:100vh;
  display:none;
  @include breakpoint (medium) {
    margin-top:34px;
  }
}
.megamenu {
  width:100%;
  background-color:$white;
  max-width:75rem;
  margin:0 auto;
  position:relative;
  box-shadow: 3px 1px 5px $gray6;
  @include breakpoint (medium) {
    padding:2rem;
    margin-top:5px;
  }
  ul {
    list-style: none;
    margin-left:0;
  }
  h5 {
    text-transform: uppercase;
    font-weight:700;
    letter-spacing:.1rem;
    font-size:1.5rem;
  }
  button {
    font-size: 3rem;
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-weight:100;
  }
}