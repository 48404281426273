.wheel-additional {
  .line-title {
    .title {
      @include breakpoint (small only) {
        width:70%;
      }
    }
    .line {
      width:33%;
      @include breakpoint (small only) {
        width:15%;
      }
    }
  }
}


.wheel-additional {
  .wheel-additional-list {
    h6 {
      font-size:.8rem;
      text-transform: uppercase;
    }
    .info {
      margin-top:.25rem;
    }
    .image {
      img {
        width:100%;
        max-width:125px;
        margin:0 auto;
        transition: all .3s ease-in-out;
        &:hover {
          transform: scale(1.08);
          opacity: .5;
        }
      }
    }
    .active-wheel {
      .image:after {
        content:'';
        border-bottom:2px solid $gray57;
        display:block;
        width:60%;
        margin:0 auto;
      }
    }
    
    
  }  
}