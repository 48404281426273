.accordion-filter {
  text-align: center;
  margin-right: 1rem;
  margin-left: 1rem;
  @include breakpoint(medium up) {
    margin-right: auto;
    margin-left: auto;
  }
  &__container {
    @include breakpoint(large up) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__header {
    font-size: .9rem;
    display: inline-block;
    margin: 0;
    font-weight:400;
    &--mobile {
      border: 1px solid $light-gray;
      padding: 1rem;
      text-transform: capitalize;
      font-size: 1rem;
      color: $black;
    }
  }
  &__content {
    border: 0;
    @include breakpoint (small only) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__dropdown {
    @include breakpoint (large up) {
      margin: 0 0.5rem;
      flex-grow: 1;
    }
    select {
      padding: 1rem;
      height: auto;
      @include breakpoint(medium up) {
        padding: 0.5rem;
      }
      @include breakpoint(large up) {
        margin: 0;
      }
    }
  }
  &__btn {
    margin: 0;
    padding: .65rem 1rem;
    font-size: 1rem;
    @include breakpoint (small only) {
      max-width: none;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}

////// off canvas overrides //////
.js-off-canvas-overlay {
  background: rgba(0,0,0,.75);
}

//// off canvas styling
.off-canvas {
  color:$black;
  > div > div {
    padding-left:2rem;
    padding-right:2rem;
  }
  &.position-left {
    /*width:445px; // Need to update js before applying....*/
    //width:375px;
    width:100%;
    @include breakpoint (medium) {
      width:445px;
    }
    //border:1px solid $gray9;
    text-transform: uppercase;
    font-size:0.825rem;
    background-color:$white;
    box-shadow: none;
  }
  &.position-right {
    display:flex;
    flex-direction:column;
    justify-content: space-between;
  }
  .close-button {
    background-color:$black;
    color:$white;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    text-align:left;
    width:100%;
    right:0;
    left:0;
    top:0;
    padding:1.25rem 2rem;
    font-size:.825rem;
    text-transform: uppercase;
    font-weight:700;
    span {
      font-weight:200;
      font-size:3rem;
    }
    /*
    padding:.5rem 2rem;
    color:$light-gray;
    margin-bottom:.5rem;
    */
    &:hover {
      color:$secondary-color;
    }
    &.orange {
      background-color:$primary-color;
      &:hover {
        color:$black;
      }
    }
  }
  .slide-over-container {
    font-size:.75rem;
    line-height:1.5;
    font-weight:700;
    padding-top:2rem;
    .slide-over-fitment {
      display:flex;
      align-items:center;
      justify-content: space-between;
      padding-top:1rem;
      padding-bottom:1rem;
      background-color:$grayE6;
      margin-bottom:2rem;
      .button {
        white-space: nowrap;
        margin-bottom:0;
        background-color:$primary-color;
        width:200px;
        height:58px;
        font-size:.75rem;
        letter-spacing:.1rem;
        margin-right:1rem;
        @include breakpoint (large) {

        }
        &:hover {
          background-color:$gray6;
        }
      }
      .fitment-info {
        font-weight:700;
        max-width:43%;
      }
    }
    .slide-over-filter {
      background-color:$white;
      select,.button {
        height:55px;
        padding:1rem;
      }
      select {
        background-position:right center;
        border-radius:2px;
        width: 100%;
        letter-spacing: .1rem;
        padding: 1rem;
        font-size: .8rem;
        text-transform: uppercase;
        font-weight:700;
      }
    }
  }
  .vertical.menu {
    padding-left:0;
    padding-top:3rem;
    margin-top:0;
    > li {
      position:relative;
      &.is-accordion-submenu-parent>a:after {
          content: '+';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          border:none;
          font-size:1.5rem;
          top:13px;
          margin-top:0;
          right:2rem;
      }
      &.is-accordion-submenu-parent[aria-expanded=true]>a:after {
          content: '-';
          transform: none;
      }
      > a {
        font-family:$body-font-family;
        color:$white;
        text-transform: uppercase;
        font-size:1.2rem;
        text-decoration: none;
        transition: all .2s ease-in-out;
        &:hover {
          color:$gray3;
        }
        .fa-caret-down {
          display:none;
        }
      }
    }
    &.is-accordion-submenu {
      > li {
        &::after {
          left:0;
        }
      }
    }
  }
  .social-menu {
    padding:1rem 1rem 2rem;
    a {
      &:hover {
        color:$gray3;
      }
    }
  }

  // Icon Filters
  .product-filters {
    .icon-product-filter {
      border-bottom:1px solid $grayC;
      padding-bottom:1.5rem;
      margin-bottom:1.5rem;
    }
  }
  .icon-filter-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-filter-items {
    padding-top:1rem;
    li {
      align-items:center;
      margin-bottom:1rem;
      line-height:1;
    }
    input {
      margin-bottom:0;
    }
    label {
      line-height:1;
    }
  }

  // wheels, wheel brands, tires {
  .wheels-slide-filter,.tires-slide-filter {
    .product-filters {
      display:block!important;
    }
      padding-left:2rem;
      padding-right:2rem;
      h4 {
        text-transform: uppercase;
        font-size:.9rem;
        letter-spacing:.1rem;
        color:$secondary-color;
        margin-bottom:0;
        font-weight:700;
      }
      ul {
        list-style: none;
      }
      label {
        font-weight:700;
        text-transform: uppercase;
      }
      i {
        &.fa-minus,&.fa-plus {
          color:$gray8;
          &:hover {
            color:$primary-color;
          }
        }
      }
      .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .filter-button {
        text-transform: uppercase;
        font-size:.75rem;
        font-weight:600;
        color:$gray4;
        &:hover {
          color:$primary-color;
        }
      }
      .filter-items {
        padding-top:1rem;
        display:none;
        li {
          align-items:center;
          margin-bottom:1rem;
          line-height:1;
        }
        input {
          margin-bottom:0;
        }
        label {
          line-height:1;
        }
      }
      .filter-brands .filter-items {
        //display:block;
      }
  
      .product-filters {
        .product-filter {
          border-bottom:1px solid $grayC;
          padding-bottom:1.5rem;
          margin-bottom:1.5rem;
        }
      }
      // active 
      .filter-active {
        //padding:1rem 2rem;
        margin-bottom:1rem;
        @include breakpoint (medium down) {
          border-bottom:1px solid $grayC;
        }
        a {
          background-color:$grayC;
          color:$black;
          border-radius: 5px;
          padding:.25rem .5rem;
          font-weight:600;
          i {
            color:$primary-color;
            transition: all .3s ease-in-out;
            font-size:inherit;
          }
          &:hover {
            background-color:$primary-color;
            color:$white;
            i {
              color:$white;
            }
          }
          &.fitmentSet {
            pointer-events: none;
            background-color:$gray9;
            i {
              color:$gray6;
            }
          }
        }
        ul {
          li {
            display:inline-block;
            margin-bottom:.5rem;
            margin-right:10px;
          }
        }
        &-clear {
          margin-bottom:1rem;
          @include breakpoint (large) {
            border-bottom:1px solid $grayC;
            padding-bottom:1.5rem;
            margin-bottom:1.5rem;
          }
          a {
            font-size: .75rem;
            font-weight: 600;
            color: $gray4;
            text-transform: uppercase;
            border:none;
            background-color:transparent;
            padding:0;
            &:hover {
              border:none;
              background-color:transparent;
              color:$black;
            }
          }
        }
        .filter-active-title {
          margin-bottom:1rem;
          h4 {
            display:flex;
            align-items:center;
            justify-content: space-between;
            width:100%;
            color:$black;
          }
        }
      }
      .filter-brands {
        label {
          max-width:260px;
          line-height:1;
          vertical-align: middle;
        }
      }
  }


  // gallery
  .slide-filter-gallery {
    margin-top:2rem;
  }
}
