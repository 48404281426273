////// header /////
.media {
  .page-header {
    h1 {
      text-transform: uppercase;
      margin-bottom:1rem;
    }
  }
}

////// body //////
.media {
  .page-content {
    @include breakpoint (medium) {
      padding-bottom:5rem;
    }
    .row {
      max-width:64rem;
    }
    .media-newsletter > .row {
      max-width:62.15rem;
    }
  }
  .title {
    font-size:1.25rem;
    color:$gray5;
    font-weight:600;
    span {
      font-weight:500;
    }
  }
}

.media {
  &-list {
    a {
      box-shadow: 0 3px 10px $gray9;
      display:block;
      &:hover {
        transform: scale(1.05);
        .thumbnail {
          transform: none;
        }
      }
    }
    img {
      width:100%;
    }
    .thumbnail {
      margin-bottom:0;
      width:100%;
    }
    .title {
      background-color:$black;
      color:$grayC;
      text-align:center;
      padding-top:2rem;
      padding-bottom:2rem;
    }
  }
  &-item {
    margin-bottom:2rem;
  }
  
  
  
  // newsletter
  &-newsletter {
    label {
      color:$grayC;
      font-size:1.15rem;
      width:auto;
      height:52px;
      border-left:1px solid $grayC;
      padding-left:3rem;
      display:flex;
      align-items: center;
      font-weight:600;
    }
    input {
      max-width:300px;
      margin-bottom:0;
      height:52px;
      margin-left:1rem;
    }
    .row {
      max-width:73.15rem;
      background-color:$black;
      color:$grayC; 
      padding:3rem 2rem;
      text-transform: uppercase;
    }
    .newsletter-item {
      display:flex;
      justify-content: space-between;
      align-items:center;
      .icon {
        padding-right:1rem;
      }
      .title {
        line-height:1.1;
        max-width:200px;
        color:$grayC;
      }
      button, .button {
        background-color:$green;
        font-size:1.15rem;
        border-radius: 0;
        padding:1rem 1.75rem;
        margin-bottom:0;
        margin-left:5px;
        &:hover {
          background-color:$grayC;
        }
      }
    }
    #robly_embed_signup {
      width:100%;
    }
    form,.robly_field_group {
      display:flex;
    }
    form {
      justify-content: flex-end;
      label {
        max-width:110px;
        width:100%;
      }
      input[name="EMAIL"] {
        width:100%;
      }
    }
    .robly_field_group {
      max-width:410px;
      width:100%;
    }
    
    
    .column:first-child {
      .newsletter-item {
        
        
      }
    }
    .column:nth-child(2) {
      .newsletter-item {
        justify-content: flex-end;
      }
    }
  }
}

