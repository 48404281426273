.overview {
  .page-header {
    @include breakpoint (small only) {
      text-align:left;
      padding-left:.5rem;
      padding-right:.5rem;
      h1 {
        font-size:1.45rem;
      }
    }
  }
  .page-content {
    .column {
      display:flex;
      align-items:center;
      justify-content: center;
    }
  }
  &-content {
    max-width:400px;
    @include breakpoint (medium down) {
      padding:2rem 2rem 4rem;
      max-width: none;
    }
  } 
  &-item {
    width:100%;
    
    img {
      width:100%;
    }
    .title,.information {
      margin-bottom:2rem;
    }
    .title {
      text-transform: uppercase;
      color:$black;
      font-size:1.85rem;
      font-weight:600;
      line-height:1;
    }
    .information {
      font-weight:400;
    }
    a {
      background-color:$black;
      color:$white;
      width:220px;
      font-weight:600;
      padding-top:1.25rem;
      padding-bottom:1.25rem;
      text-align:center;
      display:block;
      border-radius:3px;
      &:hover {
        background-color:$green;
      }
    }
  }
}