/* ==========================================================================
   0. variables
   ========================================================================== */

$gray1D:#1d1d1d;
$gray6A:#6a6a6a;
$grayEA:#eaeaea;
$grayF3:#f3f3f3;
$grayA6:#a6a6a6;

$black0:#000000;

$orange:#ff4d0d;
$red:#cc0000;


$gray3:#333333;
$gray4:#444444;
$gray5:#555555;
$gray54:#545454;
$gray57:#575757;
$gray6:#666666;
$gray79:#797979;
$gray8:#888888;
$gray8b:#8b8b8b;
$gray9:#999999;
$grayA:#aaaaaa;
$grayB:#bbbbbb;
$grayB9:#b9b9b9;
$grayC:#cccccc;
$grayD:#dddddd;
$grayD4:#d4d4d4;
$grayED:#ededed;
$grayE:#eeeeee;
$grayEF:#efefef;
$grayE6:#e6e6e6;
$grayE7:#e7e7e7;
$grayF1:#f1f1f1;
$grayF4:#f4f4f4;
$gray:$gray8;

$whiteF:#ffffff;
$whiteF1:#f1f1f1;
$whiteF2:#f2f2f2;

$pink: #f9632e;
$green2:#3ea20a;
$yellow:#ffcc33;

$blue:#0066cc;
$blueDeep:#23426a;

$green:#2ca900;

$primary-color:$yellow;
$secondary-color:$blue;

$body-font-family:'Roboto', sans-serif;
$heading-font-family:'Montserrat', sans-serif;
$detail-font-family: 'Arial', sans-serif;

$background-hover:$primary-color;
/* ==========================================================================
   1. General (Foundation Overrides)
   ========================================================================== */

body {
  font-family: $body-font-family;
  color: $gray6;
  font-weight: 400;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 1rem;
  color: $black;
}

h1 {
  font-size: 1.85rem;
}
h2 {
  font-size: 1.85rem;
  font-weight:700;
  text-transform: uppercase;
  letter-spacing:.1rem;
}
h3 {
  font-size:2rem;
}
h4 {
  font-size:1.75rem;
}
h5 {
  font-size:1.35rem;
}
h6 {
  font-size:1.25rem;
}

a {
  color: $secondary-color;
  transition: all .2s ease-in-out;
  &:hover {
    color: $gray9;
  }
  &:focus {
    color:$black;
  }
}

ul,
ol {
  margin-right: 0;
  margin-bottom: 0;
}

table {
  min-width: 100%;
  margin-bottom: 0;
  border: 0;
}

table tr td,
table thead tr th {
  text-align: center;
}

.bg-img {
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none !important;
}

.product-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-right: 1rem;
}

.info {
  &--limit {
    line-height: 1.2em;
    overflow: hidden;
    margin-bottom: 0;
  }
  &--height {
    height: 6em;
  }
}
.int.site-content {
  //margin-bottom:3rem;
}
.parallax {
  position: relative;
  overflow: hidden;
  text-align: center;
  h1,
  h2 {
    position: relative;
    padding: 1.8rem;
    font-size: 2.25rem;
    top: 25%;
  }
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .bg-top {
      background-position: center top;
    }
  }
  .img:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: 0.4;
    background: #000;
  }
}

.page-header {
  text-align: right;
  padding:1rem .5rem;
  @include breakpoint (large) {
    padding-top:3rem;
    padding-bottom:1rem; 
  }
  h1 {
    color:$black;
    font-weight:600;
    text-transform: uppercase;
    margin-bottom:0;
    text-align:center;
    letter-spacing:.1rem;
    font-size:1.25rem;
    @include breakpoint (large) {
      font-size:1.85rem;
    }
    span {
      font-weight:400;
    }
  }
}
// configurator page header //
#configurator {
  .page-header {
    padding-bottom: 0;
  }
}
// END - configurarto page header //

.white-popup {
  background: $white;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
  position: relative;
  width: auto;
  padding-top:2rem;
  .popup-header {
    font-size: 1.2rem;
  }
  .button-group {
    margin-bottom: 0;
    overflow: auto;
    button {
      &:first-child {
        margin-right:.25rem;
      }
      &:last-child {
        margin-left:.25rem;
      }
    }
  }
}

.no-results {
  color: $black;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  color: white;
  display: none;
  &--nav {
    display: block;
    top: 3.2rem;
  }
}

.primary {
  color: $primary-color;
  display:block;
  font-weight:800;
}

.primary-bg {
  background-color: $primary-color;
}

.primary-btn {
  border-radius: 4px;
  padding: 0.8rem 1.9rem;
  margin: 0;
  font-weight: 700;
  a {
    color: $white;
  }
}

.red-bg {
  background-color: $primary-color;
}

.black-font {
  color: #333;
}

.red {
  color: $primary-color;
}

.white {
  color: #fefefe;
}

.button {
  font-family:$heading-font-family;
  font-weight: bold;
  background-color: $secondary-color;
  border-radius: 2px;
  padding:1rem 1.5rem;
  letter-spacing:.1rem;
  &:hover {
    background-color:$gray9;
  }
}

.no-margin {
  margin-bottom: 0;
}

.button.secondary {
  background-color: #e7e7e7;
  color: #323232;
}

.black,
.button.black {
  background-color: #000000;
}

.button,
.text-uppercase {
  text-transform: uppercase;
}

.button-group .button {
  padding-right: 0;
  padding-left: 0;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-heavy {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.g-recaptcha {
  margin-bottom: 0.5rem;
  display: inline-block;
}

#captchaCheck {
  float: left;
  margin-left: 1rem;
  text-transform: uppercase;
}

.padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded.extra {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padded.short {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padded-left {
  padding-left: 0.5rem;
}

.padded-right {
  padding-right: 0.5rem;
}

.container-padding--b {
  padding-bottom: 4rem;
}

.accordion .accordion-navigation>.content {
  padding-right: 0;
  padding-left: 0;
}

.accordion-item:first-child> :first-child {
  font-weight: 700;
}

.anchor {
  position: relative;
  top: -3rem;
  display: block;
  visibility: hidden;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.menu.simple>li>a {
  padding: 0;
}

.menu>li:not(.menu-text)>a {
  padding: 1rem;
}

.fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}

.title-bar,
.top-bar {
  padding: 0;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
}

.top-bar ul.is-dropdown-submenu {
  background-color: rgba(255, 255, 255, 1);
  border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow>a::after {
  right: 0px;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
  padding-right: 1rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
  border-color: $primary-color transparent transparent;
  right: 0px;
  display: none;
}

.dropdown.menu.vertical>li.opens-right>a::after {
  border-color: transparent transparent transparent $primary-color;
  right: 5px;
}

.dropdown.menu>li>a:hover,
.dropdown.menu>li>ul li a:hover,
.dropdown.menu>li.is-active>a {
  color: $primary-color;
}

.dropdown.menu>li>ul li a {
  padding: 0.5rem 0rem;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  top: 154%;
  min-width: 280px;
  padding-bottom: 20px;
}

address {
  font-style: normal;
}

#instafeed .column {
  padding: 1px;
}

@media only screen and (min-width: 40.063em) {
  .int.site-content .h2 {
    font-size: 2.6rem;
  }
}

/********* VIDEO *********/

#videos {
  margin-top: 3rem;
  a:hover {
    color: #666;
  }
  a::before {
    background: rgba(255, 255, 255, 0);
  }
  p {
    font-size: 1.6rem;
  }
  #next-button {
    background-color: #fff;
  }
  #video-container .column a {
    position: relative;
    display: block;
  }
}

// ********** POPUP ********** //
.white-popup--wide {
  max-width: 700px;
}

// ********** HOVER ITEMS ********** //
.hover-item-link {
  display: block;
  padding: 1rem 0;
}

.hover-item {
  position: relative;
  display: block;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  text-align: center;
  &:hover {
    .hover-item__img {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
  &__header {
    line-height: 1.2;
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  &__header,
  h6 small {
    color: #545454;
  }
  &__img {
    -webkit-transition: -webkit-all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.7rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

// ********** Product Detail Popup Override ********** //
.popup-bkg .mfp-img {
  background-color: #fff;
}

.popup-bkg .mfp-image-holder .mfp-close {
  color: #000;
  right: 0;
}


////// line titles ///////
.line-title {
  display:flex;
  align-items: center;
  margin-bottom:1rem;
  justify-content: center;
  @include breakpoint (medium) {
    margin-bottom:2rem;
  }
  .line {
    height:1px;
    background-color:$gray57;
    width:33%;
    @include breakpoint (medium) {
      width:100%;
    }
    @media screen and (max-width:430px) {
      width:20%;
    }
  }
  .title {
    font-weight:600;
    width:50%;
    text-align: center;
    text-transform: uppercase;
    font-size:1.15rem;
    @include breakpoint (medium) {
      font-size:1.25rem;
      width:35%;
    }
    @media screen and (max-width:430px) {
      width:75%;
    }
    span {
      font-weight:500;
    }
    
  }
}

// foundation overrite
.thumbnail {
  transition:all .2s ease-in-out;
  box-shadow: none;
  border:0;
  &:hover {
    box-shadow: none;
    transform:scale(1.025);
  }
}

/* ==========================================================================
   7. loading modal
   ========================================================================== */
  @keyframes spin {
    0% { transform: rotate(0); }
    100% { transform: rotate(-1turn); }
  }
  #loading-modal {
    text-align:center;
    color:$white;
    font-size:2rem;
    .fa-spin {
    animation:spin 1s linear infinite;
    }
  }
/* ==========================================================================
   7. foundation
   ========================================================================== */
.row {
  max-width:90rem;
}

/* ==========================================================================
   7. popup
   ========================================================================== */
.mfp-wrap {
  top:0!important;
  h4 {
    color:$secondary-color;
    font-weight:700;
    text-transform: uppercase;
  }
  p {
    font-family:$detail-font-family;
    line-height:1.5;
    font-size:.8;
    text-align:center;
  }
  #contact-form {
    font-family:$detail-font-family;
  }
}

/* ==========================================================================
   7. FOUC - flash of unstyled content
   ========================================================================== */
.no-js {
  display:none;
}

/* ==========================================================================
   7. Fitment loader 
   ========================================================================== */
.white-popup.processing {
  background-color: transparent;
  color:$white;
  text-align: center;
}

/* ==========================================================================
   8. Promotion 
   ========================================================================== */
// listing sidebar
.promotion {
  text-align:center;
  padding:0 2rem;
  &-item {
    margin-bottom:1rem;
  }
}

/* ==========================================================================
   9. Front Page 
   ========================================================================== */
.hp h1 {
  display:none;
}


/* ==========================================================================
   9. loading spinner
   ========================================================================== */
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color:$grayB9;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



/* ==========================================================================
   10. Forms
   ========================================================================== */
/* honeypot */
.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}