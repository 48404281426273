.home-panels {
  &__left,
  &__center,
  &__right {
    min-height: 396px;
    position: relative;
  }

  &__left {
    background-image: url('/images/home/panel-left.jpg');
    background-size: cover;
  }

  &__center {
    background-image: url('/images/home/panel-center.jpg');
    background-size: cover;
    @media only screen and (min-width: 40rem) {
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
  }

  &__right {
    background-image: url('/images/home/panel-right.jpg');
    background-size: cover;
  }

  &__btn {
    bottom: 3rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
