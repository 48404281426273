.hp .featured-wheels {
  padding:2rem 1rem;
  @include breakpoint (large) {
    padding:5rem 3.25rem;
  }
  .section-title {
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:2rem;
    }
    h2 {
      font-family: $heading-font-family;
      position: relative;
      text-align: center;
      font-size: 1.75rem;
      padding-bottom: 0;
      margin-bottom:0;
      font-weight:900;
      letter-spacing:0;
      line-height:1.125;
  
      @include breakpoint(large up) {
        font-size: 2.5rem;
      }
    }
  }
  .section-subtitle {
    h5 {
      font-family:$body-font-family;
      font-size:1.25rem;
      letter-spacing:.1rem;
      font-weight:500;
      margin-bottom:0;
    }
  }

  .featured {
    &-slider {
      position:relative;
      @include breakpoint (large) {
        margin-bottom:3rem;
      }
    }
    &-prev, &-next {
      position: absolute;
      display: block;
      line-height: 0px;
      font-size: 2.25rem;
      cursor: pointer;
      background: transparent;
      color: $black;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 0;
      border: none;
      outline: none;
      transition:all .3s ease-in-out;
      &:hover {
        color:$gray9;
      }
    }
    &-prev {
      left:-20px;
      @include breakpoint (large) {
        left:-40px;
      }
    }
    &-next {
      right:-20px;
      @include breakpoint (large) {
        right:-40px;
      }
    }

    &-slider a {
      border:1px solid $grayC;
      display:block;
      padding:1rem;
      margin:.75rem;
      background-color:$white;
      transition:all .3s ease-in-out;
      box-shadow: 0 0 10px $grayC;
      &:hover {
        border-color:$secondary-color;
      }
    }
  }

  // slider 
  img {
    margin:0 auto;
    opacity:1;
    transition:all .3s ease-in-out;
    &:hover {
      opacity:.5;
    }
  }
  .hover-item {
    &:hover {
      img {
        opacity:.7;
        transform: scale(1.05);
      }
      .item-information {
        color:$secondary-color;
      }
    }
  }
  .item-information {
    line-height:1.25;
    color:$black;
    font-weight:600;
    text-transform: uppercase;
    margin-bottom:1rem;
    transition:all .3s ease-in-out;
    line-height:1;
    .image-image {
      margin-bottom:1rem;
    }
    .item-brand {
      font-weight:600;
      margin-bottom:.5rem;
      font-size:1.15rem;
    }
    .item-name {
      font-weight:900;
      font-family:$heading-font-family;
      font-size:1.5rem;
    }
    .item-finish {
      font-size:.9rem;
    }
  }

  .button {
    max-width:260px;
    width:100%;
    background-color:$gray3;
    border-radius:3px;
    @include breakpoint (medium down) {
      font-size:1rem;
      max-width:200px;
    }
    &:hover {
      background-color:$primary-color;
    }
  }
}