///// Header /////
.mediakit {
  .page-header {
    p {
      font-weight:400;
      font-size:1.15rem;
    }
    .column {
      display:flex;
      align-items: center;
    }
  }
}


///// Page Content /////
.mediakit {
  .page-content {
    background-color:$grayF1;
  }
  // kit bar
  .kit-bar {
    background-color:$gray8b;
    margin-bottom: 1rem;
    @include breakpoint (medium) {
      margin-bottom:2rem;
    }
    @include breakpoint (large) {
      margin-bottom:4rem;
    }
  }
  .tabs {
    border:0;
    background-color:transparent;
    a {
      color:$grayC;
      text-transform: uppercase;
      font-weight:700;
      padding:1.25rem 2.15rem;
      font-size:1.15rem;
    }
    .is-active {
      position: relative;
      &:after {
        position: absolute;
        content:'';
        display: block;
        height:5px;
        width:100%;
        background-color: $green;
      }
      a {
        color:$white;
        background-color:transparent;
      }
    }
  }
  
  // tab content 
  .tabs-content {
    background-color:transparent;
    border:0;
  }
  .kit-item {
    padding-bottom:1rem;
    @include breakpoint (medium) {
      padding-bottom:2rem;
    }
    @include breakpoint (large) {
      padding-bottom:3rem;
    }
    a {
      color:$gray5;
      &:hover {
        color:$gray9;
      }
    }
    .thumbnail {
      font-size:4rem;
    }
    .title {
      font-size:1.15rem;
      font-weight:700;
    }
  }
  .wheels {
    .series {
      padding-bottom:1rem;
      margin-bottom:2rem;
    }
    .divider {
      margin-bottom:3rem;
      border-bottom:1px solid $gray6;
    }
    .wheel {
      &-image {
        &-container {
          padding-left:.5rem;
          padding-right:.5rem;
        }
      }
      &-information {
        margin-bottom:2rem;
        color:$gray6;
        padding-left:.5rem;
        padding-right:.5rem;
      }
      &-name {
        font-weight:700;
        text-transform: uppercase;
        font-size:1.15rem;
        line-height:1.3;
      }
      &-finish {
        font-weight:400;
      }
    }
  }
}


///// Login /////
.mediakit-downloads {
  padding-bottom:10rem;
  h3 {
    span {
      font-weight:400;
    }
  }
  a {
    color:$gray6;
    font-size:1rem;
    font-weight:400;
    &:hover {
      color:$grayC;
    }
  }
}





